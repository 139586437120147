export const CPD_SET_SIDEBAR = 'CPD_SET_SIDEBAR';
export const CPD_SET_VIEW = 'CPD_SET_VIEW';
export const CPD_SET_FILTERS = 'CPD_SET_FILTERS';
export const CPD_SET_FILTERS_DATA = 'CPD_SET_FILTERS_DATA';
export const CPD_SET_FILTERS_LOADING = 'CPD_SET_FILTERS_LOADING';
export const CPD_SET_GRID = 'CPD_SET_GRID';
export const CPD_SET_DATA = 'CPD_SET_DATA';
export const CPD_SET_ROW = 'CPD_SET_ROW';
export const CPD_SET_LOADING = 'CPD_SET_LOADING';
export const CPD_SET_GRID_LOADING = 'CPD_SET_GRID_LOADING';
export const CPD_SET_GRID_RELOAD_DATA = 'CPD_SET_GRID_RELOAD_DATA';
export const CPD_SET_GRID_DATA = 'CPD_SET_GRID_DATA';
export const CPD_SET_TOTAL_ROWS = 'CPD_SET_TOTAL_ROWS';
export const CPD_SET_LAST_PAGE = 'CPD_SET_LAST_PAGE';
export const CPD_SET_MODAL_DETAILS = 'CPD_SET_MODAL_DETAILS';
export const CPD_SET_DATA_GRID_ONLY_ROWS_OF_INTEREST =
  'CPD_SET_DATA_GRID_ONLY_ROWS_OF_INTEREST';
export const CPD_SET_ROW_VIDEO = 'CPD_SET_ROW_VIDEO';
