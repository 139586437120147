import {
  SN_SET_VIEW,
  SN_SET_FILTERS,
  SN_SET_DATA,
  SN_SET_SIDEBAR,
  SN_SET_LOADING,
  SN_SET_FILTERS_DATA,
  SN_SET_FILTERS_LOADING,
  SN_SET_MODAL_LIST,
  SN_SET_MODAL_LIST_LOCATIONS,
} from './enums/DashboardTypes';

export const initialState = {
  filters: {
    loading: false,
    data: {
      divisions: [],
      site_groups: [],
      sites: [],
    },
    selected: [],
  },
  data: {
    selected_date: {},
    sites_online: 0,
    sites_deployed: 0,
    totals: {
      vehicles_count: {
        current: {
          total_of_interest: 274,
          total: 379,
        },
        previous: {
          total_of_interest: 310,
          total: 350,
        },
      },
      customer_count: {
        current: {
          total_of_interest: 0,
          total: 0,
        },
        previous: {
          total_of_interest: 0,
          total: 0,
        },
      },
      dwell_time: {
        current: {
          total: 11,
          parking: 6,
          collection: 5,
          counter: 3,
        },
        previous: {
          total: 12,
          parking: 8,
          collection: 4,
          counter: 4,
        },
      },
    },
    divisions: {
      dwell_time: {
        current: [
          {
            id: '2',
            name: 'Example Site',
            total_of_interest: 467,
          },
          {
            id: '3',
            name: 'Second Site',
            total_of_interest: 230,
          },
        ],
        previous: [
          {
            id: '2',
            total_of_interest: 300,
          },
          {
            id: '3',
            total_of_interest: 900,
          },
        ],
      },
      vehicles_count: {
        current: [
          {
            id: '2',
            name: 'Example Site',
            total: 4,
            total_of_interest: 3,
          },
          {
            id: '3',
            name: 'Second Site',
            total: 6,
            total_of_interest: 5,
          },
        ],
        previous: [
          {
            id: '2',
            total: 2,
            total_of_interest: 2,
          },
          {
            id: '3',
            total: 1,
            total_of_interest: 1,
          },
        ],
      },
      people_count: {
        current: [
          {
            id: '2',
            name: 'Example Site',
            total: 4,
            total_of_interest: 3,
          },
          {
            id: '3',
            name: 'Second Site',
            total: 6,
            total_of_interest: 5,
          },
        ],
        previous: [
          {
            id: '2',
            total: 2,
            total_of_interest: 2,
          },
          {
            id: '3',
            total: 1,
            total_of_interest: 1,
          },
        ],
      },
    },
    site_groups: null,
    sites: null,
    top_offenders_sites: [
      {
        current: {
          total_of_interest: 18,
          name: 'Branch 1',
          percentage: 40,
        },
        previous: {
          total_of_interest: 16,
        },
      },
      {
        current: {
          total_of_interest: 16,
          name: 'Branch 2',
          percentage: 30,
        },
        previous: {
          total_of_interest: 15,
        },
      },
      {
        current: {
          total_of_interest: 14,
          name: 'Branch 3',
          percentage: 20,
        },
        previous: {
          total_of_interest: 14,
        },
      },
      {
        current: {
          total_of_interest: 15,
          name: 'Branch 4',
          percentage: 20,
        },
        previous: {
          total_of_interest: 14,
        },
      },
      {
        current: {
          total_of_interest: 18,
          name: 'Branch 5',
          percentage: 20,
        },
        previous: {
          total_of_interest: 14,
        },
      },
      {
        current: {
          total_of_interest: 16,
          name: 'Branch 6',
          percentage: 20,
        },
        previous: {
          total_of_interest: 14,
        },
      },
      {
        current: {
          total_of_interest: 18,
          name: 'Branch 7',
          percentage: 20,
        },
        previous: {
          total_of_interest: 14,
        },
      },
      {
        current: {
          total_of_interest: 28,
          name: 'Branch 8',
          percentage: 50,
        },
        previous: {
          total_of_interest: 14,
        },
      },
      {
        current: {
          total_of_interest: 14,
          name: 'Branch 9',
          percentage: 20,
        },
        previous: {
          total_of_interest: 14,
        },
      },
      {
        current: {
          total_of_interest: 16,
          name: 'Branch 10',
          percentage: 20,
        },
        previous: {
          total_of_interest: 14,
        },
      },
    ],
    line_chart: {
      dwell_time: [
        {
          label: '7:00 am',
          total: 14,
          parking: 8,
          collection: 6,
          counter: 5,
        },
        {
          label: '8:00 am',
          total: 11,
          parking: 7,
          collection: 4,
          counter: 4,
        },
        {
          label: '9:00 am',
          total: 13,
          parking: 6,
          collection: 7,
          counter: 5,
        },
        {
          label: '10:00 am',
          total: 15,
          parking: 8,
          collection: 7,
          counter: 4,
        },
        {
          label: '11:00 am',
          total: 18,
          parking: 10,
          collection: 8,
          counter: 7,
        },
        {
          label: '12:00 am',
          total: 12,
          parking: 6,
          collection: 6,
          counter: 4,
        },
        {
          label: '1:00 pm',
          total: 15,
          parking: 9,
          collection: 6,
          counter: 7,
        },
        {
          label: '2:00 pm',
          total: 12,
          parking: 5,
          collection: 7,
          counter: 3,
        },
        {
          label: '3:00 pm',
          total: 17,
          parking: 9,
          collection: 8,
          counter: 7,
        },
        {
          label: '4:00 pm',
          total: 14,
          parking: 8,
          collection: 6,
          counter: 5,
        },
      ],
      vehicles_count: [],
      people_count: [],
      wait_time_analysis: [],
    },
  },
  sidebar: false,
  loading: false,
  modalList: false,
  modalListLocations: false,
};

const DashBoardSiteOneReducer = (state = initialState, action) => {
  switch (action.type) {
    case SN_SET_VIEW:
      return { ...state, filters: { ...state.filters, view: action.data } };
    case SN_SET_DATA:
      return { ...state, data: action.data };

    case SN_SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, selected: action.data },
      };
    case SN_SET_FILTERS_DATA:
      return { ...state, filters: { ...state.filters, data: action.data } };
    case SN_SET_FILTERS_LOADING:
      return { ...state, filters: { ...state.filters, loading: action.value } };
    case SN_SET_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case SN_SET_LOADING:
      return { ...state, loading: action.value };
    case SN_SET_MODAL_LIST:
      return { ...state, modalList: !state.modalList };
    case SN_SET_MODAL_LIST_LOCATIONS:
      return { ...state, modalListLocations: !state.modalListLocations };
    default:
      return state;
  }
};
export default DashBoardSiteOneReducer;
