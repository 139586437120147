import PutRequest from 'app/actions/PutRequest';

const ApplyVerifyChangeEmailAction =
  (email, hash, onSuccess, onError) => (dispatch) => {
    PutRequest({
      endPoint: `/user/verify-change-email`,
      params: { email, hash },
      onSuccess: () => {
        onSuccess();
      },
      onFailure: (error) => {
        onError();
        console.warn(error);
      },
    });
  };
export default ApplyVerifyChangeEmailAction;
