import {
  CPD_SET_VIEW,
  CPD_SET_FILTERS,
  CPD_SET_DATA,
  CPD_SET_ROW,
  CPD_SET_SIDEBAR,
  CPD_SET_LOADING,
  CPD_SET_MODAL_DETAILS,
  CPD_SET_GRID,
  CPD_SET_GRID_DATA,
  CPD_SET_TOTAL_ROWS,
  CPD_SET_LAST_PAGE,
  CPD_SET_GRID_LOADING,
  CPD_SET_FILTERS_DATA,
  CPD_SET_FILTERS_LOADING,
  CPD_SET_GRID_RELOAD_DATA,
  CPD_SET_DATA_GRID_ONLY_ROWS_OF_INTEREST,
  CPD_SET_ROW_VIDEO,
} from './enums/DashboardTypes';

export const initialState = {
  filters: {
    loading: false,
    data: {
      stores: [],
      terminals: [],
      transactionTypes: [],
      transactionSubTypes: [],
      clerks: [],
      status: [],
      threshold: { min: 0, max: 0 },
      totalItemsThreshold: { min: 0, max: 10 },
    },
    selected: [],
  },
  data: {
    sites_online: 7,
    sites_deployed: 2,
    totals: {
      current: {
        total: 0,
        total_of_interest: 0,
      },
      previous: {
        total: 0,
        total_of_interest: 0,
      },
    },
    amount: {
      current: {
        total: 0,
        total_of_interest: 0,
      },
      previous: {
        total: 0,
        total_of_interest: 0,
      },
    },
    stores: {
      current: [],
      previous: [],
    },
    clerks: {
      current: [],
      previous: [],
    },
    line_chart: [],
  },
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    loading: false,
    reloadData: 0,
    showOnlyRowsOfInterest: true,
  },
  row: [],
  sidebar: false,
  modalDetails: false,
  loading: false,
};

const DashboardCPDReducer = (state = initialState, action) => {
  switch (action.type) {
    case CPD_SET_VIEW:
      return { ...state, filters: { ...state.filters, view: action.data } };
    case CPD_SET_DATA:
      return { ...state, data: action.data };
    case CPD_SET_GRID:
      return { ...state, grid: action.data };
    case CPD_SET_GRID_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case CPD_SET_GRID_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.value } };
    case CPD_SET_GRID_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
      };
    case CPD_SET_DATA_GRID_ONLY_ROWS_OF_INTEREST:
      return {
        ...state,
        grid: { ...state.grid, showOnlyRowsOfInterest: action.value },
      };
    case CPD_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case CPD_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case CPD_SET_ROW:
      return { ...state, row: action.data };
    case CPD_SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, selected: action.data },
      };
    case CPD_SET_FILTERS_DATA:
      return { ...state, filters: { ...state.filters, data: action.data } };
    case CPD_SET_FILTERS_LOADING:
      return { ...state, filters: { ...state.filters, loading: action.value } };
    case CPD_SET_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case CPD_SET_MODAL_DETAILS:
      return { ...state, modalDetails: !state.modalDetails };
    case CPD_SET_LOADING:
      return { ...state, loading: action.value };
    case CPD_SET_ROW_VIDEO:
      return {
        ...state,
        row: {
          ...state.row,
          loading_video: false,
          video_url: action.value,
        },
      };

    default:
      return state;
  }
};
export default DashboardCPDReducer;
