export const LICENSE_SET_CURRENT_ROW = 'LICENSE_SET_CURRENT_ROW';
export const LICENSE_TOGGLE_MODAL_FORM = 'LICENSE_TOGGLE_MODAL_FORM';
export const LICENSE_TOGGLE_DELETE_MODAL = 'LICENSE_TOGGLE_DELETE_MODAL';
export const LICENSE_TOGGLE_RENEW_MODAL = 'LICENSE_TOGGLE_RENEW_MODAL';

export const LICENSE_SET_LOADING = 'LICENSE_SET_LOADING';
export const LICENSE_SET_LOADING_APPS = 'LICENSE_SET_LOADING_APPS';
export const LICENSE_SET_DATA_APPS = 'LICENSE_SET_DATA_APPS';
export const LICENSE_SET_GRID = 'LICENSE_SET_GRID';
export const LICENSE_SET_TOTAL_ROWS = 'LICENSE_SET_TOTAL_ROWS';
export const LICENSE_SET_LAST_PAGE = 'LICENSE_SET_LAST_PAGE';
export const LICENSE_TOGGLE_MODAL_OPEN = 'LICENSE_TOGGLE_MODAL_OPEN';
export const LICENSE_SET_RELOAD_DATA = 'LICENSE_SET_RELOAD_DATA';
export const LICENSE_UPDATE_DATA_APP = 'LICENSE_UPDATE_DATA_APP';
export const LICENSE_RESET_APP_IDS = 'LICENSE_RESET_APP_IDS';
export const LICENSE_SET_CURRENT_FORM_LOADING = 'LICENSE_SET_CURRENT_FORM_LOADING';
export const LICENSE_SET_LOADING_ORGS = 'LICENSE_SET_LOADING_ORGS';
export const LICENSE_SET_DATA_ORGS = 'LICENSE_SET_DATA_ORGS';
