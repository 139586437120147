import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import AuthReducer from 'auth/AuthReducer';
import AppReducer from 'app/AppReducer';
import OrganisationReducer from 'organisations/OrganisationReducer';
import SiteGroupReducer from 'site-group/SiteGroupReducer';
import SiteReducer from 'sites/SitesReducer';
import DivisionReducer from 'division/DivisionReducer';
import UserProfileReducer from 'user-profile/UserProfileReducer';
import UserReducer from 'user/UserReducer';
import AnnouncementReducer from 'announcement/AnnouncementReducer';
import UserAnnouncementReducer from 'user-announcement/UserAnnouncementReducer';
import UserGroupReducer from 'user-group/UserGroupReducer';

// Dashboard Profiles Managements
import DepartmentReducer from 'department/DepartmentReducer';

// Face Mask Detection
import DashBoardFMDReducer from 'dashboard/fmd/DashBoardFMDReducer';

// SweetHeart Detection
import DashboardSWHReducer from 'dashboard/swh/DashBoardSWHReducer';

// Customer Present Detection
import DashboardCPDReducer from 'dashboard/cpd/DashBoardCPDReducer';

// Next Generation Footfall / In Out count
import DashBoardIOCReducer from 'dashboard/ioc/DashBoardIOCReducer';

// Scan avoidance
import DashBoardSCAReducer from 'dashboard/sca/DashBoardSCAReducer';

// Store Performance
import DashBoardSTPDReducer from 'dashboard/stpd/DashBoardSTPDReducer';

// Global Count / Micro Count
import DashBoardOCCReducer from 'dashboard/occ/DashBoardOCCReducer';

// Site One
import DashBoardSiteOneReducer from 'dashboard/site-one/DashBoardSiteOneReducer';

// Customer Engagement
import DashBoardCREReducer from 'dashboard/cre/DashBoardCREReducer';

//  ManualInspection
import ManualInspectionReducer from 'manual-inspection/ManualInspectionReducer';

// Rest-A-Sure
import RestASureReducer from 'rest-a-sure/RestASureReducer';

// Associate Not Present
import DashBoardANPReducer from 'dashboard/anp/DashBoardANPReducer';

import FloorPlanReducer from 'floor-plans/FloorPlansReducer';

// Marketing Campaign
import DashBoardMACReducer from 'dashboard/mac/DashboardReducer';

import LicenseReducer from 'license/LicenseReducer';

const reducer = combineReducers({
  app: AppReducer,
  announcement: AnnouncementReducer,
  auth: AuthReducer,
  division: DivisionReducer,
  license: LicenseReducer,
  organisation: OrganisationReducer,
  siteGroup: SiteGroupReducer,
  site: SiteReducer,
  userProfile: UserProfileReducer,
  userAnnouncement: UserAnnouncementReducer,
  user: UserReducer,
  userGroup: UserGroupReducer,
  department: DepartmentReducer,
  manualInspectionReducer: ManualInspectionReducer,
  restASureReducer: RestASureReducer,
  dashboardFMDReducer: DashBoardFMDReducer,
  dashboardSWHReducer: DashboardSWHReducer,
  dashboardCPDReducer: DashboardCPDReducer,
  dashBoardIOCReducer: DashBoardIOCReducer,
  dashBoardSCAReducer: DashBoardSCAReducer,
  dashBoardSTPDReducer: DashBoardSTPDReducer,
  dashBoardOCCReducer: DashBoardOCCReducer,
  dashBoardSiteOneReducer: DashBoardSiteOneReducer,
  dashBoardCREReducer: DashBoardCREReducer,
  dashBoardANPReducer: DashBoardANPReducer,
  dashBoardMACReducer: DashBoardMACReducer,
  floorPlanReducer: FloorPlanReducer
});
const store = createStore(reducer, applyMiddleware(thunk));
export default store;
