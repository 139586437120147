import {
  ANP_SET_VIEW,
  ANP_SET_FILTERS,
  ANP_SET_DATA,
  ANP_SET_ROW,
  ANP_SET_SIDEBAR,
  ANP_SET_LOADING,
  ANP_SET_MODAL_DETAILS,
  ANP_SET_GRID,
  ANP_SET_GRID_DATA,
  ANP_SET_TOTAL_ROWS,
  ANP_SET_LAST_PAGE,
  ANP_SET_GRID_LOADING,
  ANP_SET_FILTERS_DATA,
  ANP_SET_FILTERS_LOADING,
  ANP_SET_GRID_RELOAD_DATA,
  ANP_SET_ROW_IMAGE,
} from './enums/DashboardTypes';

export const initialState = {
  filters: {
    loading: false,
    data: {
      sites: [],
      cameras: [],
    },
    selected: [],
  },
  data: {
    selected_date: {
      current: {
        start: '',
        end: '',
      },
      previous: {
        start: '',
        end: '',
      },
    },
    sites_online: 1,
    sites_deployed: 1,
    totals: {
      current: {
        total_of_interest: 0,
      },
      previous: {
        total_of_interest: 0,
      },
    },
    sites: {
      current: [],
      previous: [],
    },
    cameras: {
      current: [],
      previous: [],
    },
    line_chart: [],
  },
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    loading: false,
    reloadData: 0,
  },
  row: {},
  sidebar: false,
  modalDetails: false,
  loading: false,
};

const DashBoardANPReducer = (state = initialState, action) => {
  switch (action.type) {
    case ANP_SET_VIEW:
      return { ...state, filters: { ...state.filters, view: action.data } };
    case ANP_SET_DATA:
      return { ...state, data: action.data };
    case ANP_SET_GRID:
      return { ...state, grid: action.data };
    case ANP_SET_GRID_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case ANP_SET_GRID_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.value } };
    case ANP_SET_GRID_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
      };
    case ANP_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case ANP_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case ANP_SET_ROW:
      return { ...state, row: action.data };
    case ANP_SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, selected: action.data },
      };
    case ANP_SET_FILTERS_DATA:
      return { ...state, filters: { ...state.filters, data: action.data } };
    case ANP_SET_FILTERS_LOADING:
      return { ...state, filters: { ...state.filters, loading: action.value } };
    case ANP_SET_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case ANP_SET_MODAL_DETAILS:
      return { ...state, modalDetails: !state.modalDetails };
    case ANP_SET_LOADING:
      return { ...state, loading: action.value };
    case ANP_SET_ROW_IMAGE:
      return {
        ...state,
        row: {
          ...state.row,
          loading_video: false,
          photo_url: action.value,
        },
      };
    default:
      return state;
  }
};
export default DashBoardANPReducer;
