import {
  STPD_SET_VIEW,
  STPD_SET_FILTERS,
  STPD_SET_DATA,
  STPD_SET_SIDEBAR,
  STPD_SET_LOADING,
  STPD_SET_FILTERS_DATA,
  STPD_SET_FILTERS_LOADING,
  STPD_SET_MODAL_LIST,
} from './enums/DashboardTypes';

export const initialState = {
  sitesOnline: 10,
  sitesDeployed: 5,
  filters: {
    loading: false,
    data: {
      divisions: [],
      site_groups: [],
      sites: [],
    },
    selected: [],
  },
  data: {
    selected_date: {},
    totals: {
      current: {},
      previous: {},
    },
    stores: [],
    line_chart: [],
    daily_averages: [],
  },
  sidebar: false,
  loading: false,
  modalList: false,
};

const DashBoardDSTPDReducer = (state = initialState, action) => {
  switch (action.type) {
    case STPD_SET_VIEW:
      return { ...state, filters: { ...state.filters, view: action.data } };
    case STPD_SET_DATA:
      return { ...state, data: action.data };

    case STPD_SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, selected: action.data },
      };
    case STPD_SET_FILTERS_DATA:
      return { ...state, filters: { ...state.filters, data: action.data } };
    case STPD_SET_FILTERS_LOADING:
      return { ...state, filters: { ...state.filters, loading: action.value } };
    case STPD_SET_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case STPD_SET_LOADING:
      return { ...state, loading: action.value };
    case STPD_SET_MODAL_LIST:
      return { ...state, modalList: !state.modalList };
    default:
      return state;
  }
};
export default DashBoardDSTPDReducer;
