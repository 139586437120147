import HandleUnauthenticatedReply from 'app/actions/HandleUnauthenticatedReply';
import axios from 'axios';
import Qs from 'qs';
import { addToast } from 'app/Toast';
import AuthToken from './AuthToken';
import { getOAuthRefreshToken } from './RefreshTokenService';

let isWaitingTokenRefresh = false;
let failedQueue = [];
let requestAttempts = [];

/* Method for processing the queued requests */
const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
  requestAttempts = [];
};

export default function ApiRequest({
  method,
  url,
  params = null,
  onSuccess = null,
  onFailure = null,
  onFinally = null,
  onAccessDenied = null,
  headerContentType = 'application/json',
}) {
  /* Intercept all axios responses */
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      const originalRequest = error.config;
      const refreshToken = AuthToken.getRefreshToken();
      const itemKey = `${originalRequest?.method}${originalRequest?.url}`;
      const isRetrying = requestAttempts.includes(itemKey);

      /* OAuth refresh token has failed, send user to login page */
      if (
        error?.response?.status === 401 &&
        originalRequest.url.includes('/api/refresh-token')
      ) {
        return HandleUnauthenticatedReply();
      }

      if (
        originalRequest.url.includes('/api/login') ||
        originalRequest.url.includes('/api/two-fa') ||
        originalRequest.url.includes('/api/mfa')
      ) {
        return Promise.reject(error);
      }

      /* Request return 401(Unauthenticated), dispatch request to refresh OAuth token */
      if (refreshToken && error?.response?.status === 401 && !isRetrying) {
        /* Waiting previous refresh token request, include it in queue */
        if (isWaitingTokenRefresh) {
          return new Promise((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          })
            .then((token) => {
              originalRequest.headers.Authorization = `Bearer ${token}`;
              return axios(originalRequest);
            })
            .catch((err) => Promise.reject(err));
        }

        /* include url as already attempted */
        requestAttempts.push(itemKey);

        /* Update internal waiting flag */
        isWaitingTokenRefresh = true;

        /* Dispatch request */
        return new Promise((resolve, reject) => {
          getOAuthRefreshToken()
            .then(({ data }) => {
              axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
              originalRequest.headers.Authorization = `Bearer ${data.access_token}`;
              processQueue(null, data.access_token);
              resolve(axios(originalRequest));
            })
            .catch((err) => {
              console.log(err);
              console.log('catch error', itemKey);
              processQueue(err, null);
              reject(err);
            })
            .finally(() => {
              isWaitingTokenRefresh = false;
            });
        });
      }
      return Promise.reject(error);
    }
  );

  /* Add cypress identification header.
     It's being used to change the email dispatching service when running cypress tests  
   */
  const isCypressObject = window.Cypress ? { 'Is-Cypress': true } : {};

  return axios
    .request({
      url,
      method,
      headers: {
        'Content-Type': headerContentType,
        Authorization: `Bearer ${AuthToken.getToken()}`,
        ...isCypressObject,
      },
      data: params,
      params,
      paramsSerializer(newParams) {
        return Qs.stringify(newParams, { arrayFormat: 'brackets' });
      },
    })
    .then(({ data }) => {
      onSuccess(data);
    })
    .catch((error) => {
      if (onFailure) onFailure(error);
    })
    .finally(() => {
      if (onFinally) onFinally();
    });
}
export const showError = (dataErrors, formikSetErrors = null) => {
  let validationErrors = {};
  if (typeof dataErrors?.data === 'object') {
    Object.keys(dataErrors?.data).forEach((key) => {
      validationErrors = {
        ...validationErrors,
        [key]: dataErrors?.data[key],
      };
    });
  }

  if (Object.entries(validationErrors).length && formikSetErrors) {
    formikSetErrors(validationErrors);
  } else {
    addToast('Something went wrong, please contact your administrator', {
      appearance: 'error',
      autoDismiss: true,
    });
  }
};
