import {
  OCC_SET_VIEW,
  OCC_SET_FILTERS,
  OCC_SET_DATA,
  OCC_SET_SIDEBAR,
  OCC_SET_LOADING,
  OCC_SET_FILTERS_DATA,
  OCC_SET_FILTERS_LOADING,
  OCC_SET_MODAL_LIST,
  OCC_SET_MODAL_LIST_ZONES,
} from './enums/DashboardTypes';

export const initialState = {
  sitesOnline: 1,
  sitesDeployed: 1,
  filters: {
    loading: false,
    data: {
      zones: [],
      sites: [],
    },
    selected: [],
  },
  data: {
    selected_date: {},
    totals: {
      current: {
        avg: 0,
        max: 0,
      },
      previous: {
        avg: 0,
        max: 0,
      },
    },
    sites: {
      current: [],
      previous: [],
    },
    zones: {
      current: [],
      previous: [],
    },
    line_chart: [],
  },
  sidebar: false,
  loading: false,
  modalList: false,
  modalListZones: false,
};

const dashBoardOCCReducer = (state = initialState, action) => {
  switch (action.type) {
    case OCC_SET_VIEW:
      return { ...state, filters: { ...state.filters, view: action.data } };
    case OCC_SET_DATA:
      return { ...state, data: action.data };

    case OCC_SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, selected: action.data },
      };
    case OCC_SET_FILTERS_DATA:
      return { ...state, filters: { ...state.filters, data: action.data } };
    case OCC_SET_FILTERS_LOADING:
      return { ...state, filters: { ...state.filters, loading: action.value } };
    case OCC_SET_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case OCC_SET_LOADING:
      return { ...state, loading: action.value };
    case OCC_SET_MODAL_LIST:
      return { ...state, modalList: !state.modalList };
    case OCC_SET_MODAL_LIST_ZONES:
      return { ...state, modalListZones: !state.modalListZones };
    default:
      return state;
  }
};
export default dashBoardOCCReducer;
