import {
  USER_PROFILE_SET_DATA,
  USER_PROFILE_SET_LOADING,
} from 'user-profile/enums/UserProfileTypes';

const initialState = {
  loading: true,
  data: {},
};

const UserProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_PROFILE_SET_DATA:
      return { ...state, data: action.data };
    case USER_PROFILE_SET_LOADING:
      return { ...state, loading: action.action };
    default:
      return state;
  }
};
export default UserProfileReducer;
