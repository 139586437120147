export const MAC_SET_DATA = 'MAC_SET_DATA';
export const MAC_SET_SIDEBAR = 'MAC_SET_SIDEBAR';
export const MAC_SET_FILTERS = 'MAC_SET_FILTERS';
export const MAC_SET_FILTERS_SUMMARY = 'MAC_SET_FILTERS_SUMMARY';
export const MAC_SET_FILTERS_DATA = 'MAC_SET_FILTERS_DATA';
export const MAC_SET_FILTERS_LOADING = 'MAC_SET_FILTERS_LOADING';
export const MAC_SET_ROW = 'MAC_SET_ROW';
export const MAC_SET_LOADING = 'MAC_SET_LOADING';
export const MAC_SET_MODAL_FORM = 'MAC_SET_MODAL_FORM';
export const MAC_SET_FORM = 'MAC_SET_FORM';
export const MAC_SET_GRID_LOADING = 'MAC_SET_GRID_LOADING';
export const MAC_SET_GRID_RELOAD_DATA = 'MAC_SET_GRID_RELOAD_DATA';
export const MAC_SET_GRID = 'MAC_SET_GRID';
export const MAC_SET_GRID_DATA = 'MAC_SET_GRID_DATA';
