import {
  CRE_SET_VIEW,
  CRE_SET_FILTERS,
  CRE_SET_DATA,
  CRE_SET_SIDEBAR,
  CRE_SET_LOADING,
  CRE_SET_FILTERS_DATA,
  CRE_SET_FILTERS_LOADING,
  CRE_SET_FILTERS_SUMMARY,
  CRE_SET_SITES_DATA,
  CRE_SET_SITES_LOADING,
  CRE_SET_AGGREGATE_CHART_DATA,
  CRE_SET_AGGREGATE_CHART_DATA_LOADING,
} from './enums/DashboardTypes';

export const initialState = {
  sitesOnline: 1,
  sitesDeployed: 2,
  filters: {
    loading: false,
    data: {
      sites: [],
      zones: [],
    },
    selected2: {
      dateStart: '',
      dateEnd: '',
      previousDateStart: '',
      previousDateEnd: '',
      visualisationDateType: 1,
      siteId: [],
      durationThreshold: { min: 5, max: 120 },
    },
    selected: {},
    summary: [],
  },
  data: {
    selected_date: {},
    sites_online: 0,
    sites_deployed: 0,
    sites_online_array: [],
    totals: {
      footfall: {
        current: {
          engagement: 0,
          total: 0,
        },
        previous: {
          engagement: 0,
          total: 0,
        },
      },

      dwell_time: {
        current: {
          avg: 0,
          max: 0,
        },
        previous: {
          avg: 0,
          max: 0,
        },
      },
      occupancy: {
        current: {
          avg: 0,
          max: 0,
        },
        previous: {
          avg: 0,
          max: 0,
        },
      },
      dwell_time_breakdown: {
        current: [],
        previous: [],
      },
    },
    opening_hours_range: [],
    line_chart: [],
    line_chart_aggregate: {
      current: [],
      previous: [],
    },
    line_chart_breakdown: [],
  },
  sites: [],
  loading_sites: false,
  line_chart_aggregate: { current: [], previous: [] },
  line_chart_aggregate_loading: false,
  sidebar: false,
  loading: false,
};

const DashBoardSiteOneReducer = (state = initialState, action) => {
  switch (action.type) {
    case CRE_SET_VIEW:
      return { ...state, filters: { ...state.filters, view: action.data } };
    case CRE_SET_DATA:
      return { ...state, data: action.data };
    case CRE_SET_SITES_DATA:
      return { ...state, sites: action.data };
    case CRE_SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, selected: action.data },
      };
    case CRE_SET_FILTERS_DATA:
      return { ...state, filters: { ...state.filters, data: action.data } };
    case CRE_SET_FILTERS_LOADING:
      return { ...state, filters: { ...state.filters, loading: action.value } };
    case CRE_SET_FILTERS_SUMMARY:
      return {
        ...state,
        filters: { ...state.filters, summary: action.data },
      };
    case CRE_SET_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case CRE_SET_LOADING:
      return { ...state, loading: action.value };
    case CRE_SET_SITES_LOADING:
      return { ...state, loading_sites: action.value };
    case CRE_SET_AGGREGATE_CHART_DATA:
      return { ...state, line_chart_aggregate: action.data };
    case CRE_SET_AGGREGATE_CHART_DATA_LOADING:
      return { ...state, line_chart_aggregate_loading: action.value };
    default:
      return state;
  }
};
export default DashBoardSiteOneReducer;
