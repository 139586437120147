/* eslint-disable no-underscore-dangle */
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
import 'core-js';
import './app/polyfill';
import React, { createRef } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ApolloProvider } from '@apollo/client/react';
import apolloClient from 'app/apollo-client/Client';
import { ToastProvider } from 'react-toast-notifications';
import App from './app/App';
import { icons } from './app/assets/icons';
import store from './app/Store';
import * as serviceWorker from './serviceWorker';

React.icons = icons;
window.__react_toast_provider = React.createRef();

ReactDOM.render(
  <Provider store={store}>
    <ApolloProvider client={apolloClient}>
      <ToastProvider ref={window.__react_toast_provider}>
        <App />
      </ToastProvider>
    </ApolloProvider>
  </Provider>,

  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
serviceWorker.unregister();
