export const SITEGROUP_SET_GRID = 'SITEGROUP_SET_GRID';
export const SITEGROUP_SET_DATA = 'SITEGROUP_SET_DATA';
export const SITEGROUP_SET_DATA_ALL = 'SITEGROUP_SET_DATA_ALL';
export const SITEGROUP_SET_LAST_PAGE = 'SITEGROUP_SET_LAST_PAGE';
export const SITEGROUP_SET_TOTAL_ROWS = 'SITEGROUP_SET_TOTAL_ROWS';
export const SITEGROUP_SET_CURRENT_ROW = 'SITEGROUP_SET_CURRENT_ROW';
export const SITEGROUP_SET_RELOAD_DATA = 'SITEGROUP_SET_RELOAD_DATA';
export const SITEGROUP_SET_LOADING = 'SITEGROUP_SET_LOADING';
export const SITEGROUP_SET_LOADING_ALL = 'SITEGROUP_SET_LOADING_ALL';
export const SITEGROUP_TOGGLE_CREATE_EDIT_MODAL =
  'SITEGROUP_TOGGLE_CREATE_EDIT_MODAL';
export const SITEGROUP_TOGGLE_DELETE_MODAL = 'SITEGROUP_TOGGLE_DELETE_MODAL';
