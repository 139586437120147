import {
  DIVISION_SET_DATA,
  DIVISION_SET_LAST_PAGE,
  DIVISION_SET_TOTAL_ROWS,
  DIVISION_SET_CURRENT_ROW,
  DIVISION_SET_RELOAD_DATA,
  DIVISION_SET_LOADING,
  DIVISION_TOGGLE_CREATE_EDIT_MODAL,
  DIVISION_TOGGLE_DELETE_MODAL,
} from './enums/DivisionTypes';

const initialState = {
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    reloadData: 0,
    loading: false,
  },
  currentRow: {},
  createEditModal: false,
  deleteModal: false,
};

const DivisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case DIVISION_SET_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case DIVISION_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case DIVISION_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case DIVISION_SET_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.value } };
    case DIVISION_SET_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
      };
    case DIVISION_SET_CURRENT_ROW:
      return { ...state, currentRow: action.data };
    case DIVISION_TOGGLE_CREATE_EDIT_MODAL:
      return { ...state, createEditModal: !state.createEditModal };
    case DIVISION_TOGGLE_DELETE_MODAL:
      return { ...state, deleteModal: !state.deleteModal };
    default:
      return state;
  }
};
export default DivisionReducer;
