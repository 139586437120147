import AuthToken from 'app/helpers/AuthToken';

const HandleUnauthenticatedReply = () => {
  if (!window.location.href.includes('/login')) {
    /* Remove token from localStorage */
    AuthToken.deleteToken();
    // addToast('Your session has expired, please log in.', {
    //   appearance: 'warning',
    //   autoDismiss: true,
    // });
    window.location.href = '/login';
  }
};
export default HandleUnauthenticatedReply;
