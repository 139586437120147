export const SITE_SET_GRID = 'SITE_SET_GRID';
export const SITE_SET_DATA = 'SITE_SET_DATA';
export const SITE_SET_DATA_ALL = 'SITE_SET_DATA_ALL';
export const SITE_SET_LAST_PAGE = 'SITE_SET_LAST_PAGE';
export const SITE_SET_TOTAL_ROWS = 'SITE_SET_TOTAL_ROWS';
export const SITE_SET_CURRENT_ROW = 'SITE_SET_CURRENT_ROW';
export const SITE_SET_RELOAD_DATA = 'SITE_SET_RELOAD_DATA';
export const SITE_SET_LOADING = 'SITE_SET_LOADING';
export const SITE_SET_LOADING_ALL = 'SITE_SET_LOADING_ALL';
export const SITE_TOGGLE_CREATE_EDIT_MODAL = 'SITE_TOGGLE_CREATE_EDIT_MODAL';
export const SITE_TOGGLE_DELETE_MODAL = 'SITE_TOGGLE_DELETE_MODAL';

export const SITE_SET_SIZES_GRID = 'SITE_SET_SIZES_GRID';
export const SITE_SET_SIZES_DATA = 'SITE_SET_SIZES_DATA';
export const SITE_SET_SIZES_DATA_ALL = 'SITE_SET_SIZES_DATA_ALL';
export const SITE_SET_SIZES_LAST_PAGE = 'SITE_SET_SIZES_LAST_PAGE';
export const SITE_SET_SIZES_TOTAL_ROWS = 'SITE_SET_SIZES_TOTAL_ROWS';
export const SITE_SET_SIZES_LOADING = 'SITE_SET_SIZES_LOADING';
export const SITE_SET_SIZES_RELOAD_DATA = 'SITE_SET_SIZES_RELOAD_DATA';
export const SITE_SIZES_TOGGLE_CREATE_EDIT_MODAL =
  'SITE_SIZES_TOGGLE_CREATE_EDIT_MODAL';
export const SITE_SIZES_TOGGLE_DELETE_MODAL = 'SITE_SIZES_TOGGLE_DELETE_MODAL';
export const SITE_SET_SIZES_CURRENT_ROW = 'SITE_SET_SIZES_CURRENT_ROW';

export const SITE_SET_FORMATS_GRID = 'SITE_SET_FORMATS_GRID';
export const SITE_SET_FORMATS_DATA = 'SITE_SET_FORMATS_DATA';
export const SITE_SET_FORMATS_DATA_ALL = 'SITE_SET_FORMATS_DATA_ALL';
export const SITE_SET_FORMATS_LAST_PAGE = 'SITE_SET_FORMATS_LAST_PAGE';
export const SITE_SET_FORMATS_TOTAL_ROWS = 'SITE_SET_FORMATS_TOTAL_ROWS';
export const SITE_SET_FORMATS_LOADING = 'SITE_SET_FORMATS_LOADING';
export const SITE_SET_FORMATS_RELOAD_DATA = 'SITE_SET_FORMATS_RELOAD_DATA';
export const SITE_FORMATS_TOGGLE_CREATE_EDIT_MODAL =
  'SITE_FORMATS_TOGGLE_CREATE_EDIT_MODAL';
export const SITE_FORMATS_TOGGLE_DELETE_MODAL =
  'SITE_FORMATS_TOGGLE_DELETE_MODAL';
export const SITE_SET_FORMATS_CURRENT_ROW = 'SITE_SET_FORMATS_CURRENT_ROW';
