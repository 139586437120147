import {
  RAS_SET_FILTERS_DATA,
  RAS_SET_FILTERS_LOADING,
  RAS_SET_GRID,
  RAS_SET_GRID_LOADING,
  RAS_SET_MODAL,
  RAS_SET_ROW,
  RAS_RELOAD_GRID,
  RAS_SET_ALL_REQUESTS,
} from './enums/RestASureTypes';

export const initialState = {
  sitesOnline: 1,
  sitesDeployed: 2,
  filters: {
    loading: false,
    data: {
      divisions: [],
      site_groups: [],
      sites: [],
      cameras: [],
    },
  },
  grid: {
    data: [],
    paginatorInfo: {
      currentPage: 0,
      lastPage: 0,
      firstItem: 0,
      lastItem: 0,
      hasMorePages: 0,
      total: 0,
    },
    loading: false,
  },
  allRequests: {
    data: [],
    paginatorInfo: {
      currentPage: 0,
      lastPage: 0,
      firstItem: 0,
      lastItem: 0,
      hasMorePages: 0,
      total: 0,
    },
    loading: false,
  },
  modal: false,
  reloadGrid: 0,
  currentRow: {},
};

const RestASureReducer = (state = initialState, action) => {
  switch (action.type) {
    case RAS_SET_FILTERS_DATA:
      return { ...state, filters: { ...state.filters, data: action.data } };
    case RAS_SET_FILTERS_LOADING:
      return { ...state, filters: { ...state.filters, loading: action.value } };
    case RAS_SET_GRID:
      return { ...state, grid: action.data };
    case RAS_SET_GRID_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.value } };
    case RAS_SET_MODAL:
      return { ...state, modal: !state.modal };
    case RAS_SET_ROW:
      return { ...state, currentRow: action.data };
    case RAS_RELOAD_GRID:
      return { ...state, reloadGrid: state.reloadGrid + 1 };
    case RAS_SET_ALL_REQUESTS:
      return { ...state, allRequests: action.data };
    default:
      return state;
  }
};

export default RestASureReducer;
