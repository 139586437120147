import {
  APP_SET_TIMEZONE_DATA,
  APP_SET_TIMEZONE_LOADING,
} from './enums/appTypes';

const initialState = {
  timezoneData: [],
  timezoneLoading: false,
};

const DivisionReducer = (state = initialState, action) => {
  switch (action.type) {
    case APP_SET_TIMEZONE_DATA:
      return { ...state, timezoneData: action.data, timezoneLoading: false };
    case APP_SET_TIMEZONE_LOADING:
      return { ...state, timezoneLoading: action.value };
    default:
      return state;
  }
};
export default DivisionReducer;
