import RegistrationStep from './enums/RegistrationStep';
import {
  /* Organisations */
  ORGANISATION_SET_GRID,
  ORGANISATION_SET_DATA,
  ORGANISATION_SET_DATA_ALL,
  ORGANISATION_SET_CURRENT_ROW,
  ORGANISATION_SET_TOTAL_ROWS,
  ORGANISATION_SET_LAST_PAGE,
  ORGANISATION_SET_LOADING,
  ORGANISATION_SET_LOADING_ALL,
  ORGANISATION_SET_RELOAD_DATA,
  ORGANISATION_TOGGLE_EDIT_MODAL,
  ORGANISATION_TOGGLE_DELETE_MODAL,

  /* Invite/Invited Organisations */
  ORGANISATION_SET_GRID_INVITED,
  ORGANISATION_SET_DATA_INVITED,
  ORGANISATION_SET_CURRENT_ROW_INVITED,
  ORGANISATION_SET_TOTAL_ROWS_INVITED,
  ORGANISATION_SET_LAST_PAGE_INVITED,
  ORGANISATION_SET_LOADING_INVITED,
  ORGANISATION_SET_RELOAD_DATA_INVITED,
  ORGANISATION_TOGGLE_INVITE_MODAL,
  ORGANISATION_TOGGLE_APPROVE_MODAL,
  ORGANISATION_TOGGLE_EDIT_INVITED_MODAL,
  ORGANISATION_TOGGLE_RESEND_MODAL,
  ORGANISATION_TOGGLE_DELETE_MODAL_INVITED,
  ORGANISATION_TOGGLE_MODAL_FORM,
  ORGANISATION_SET_CURRENCIES,

  /* Register */
  ORGANISATION_SET_REGISTRATION_STEP,
  ORGANISATION_TOGGLE_IMPERSONATE_MODAL,
} from './enums/OrganisationTypes';

const initialState = {
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    reloadData: 0,
    loading: false,
  },
  /* Organisations */
  dataAll: [],
  currentRow: {},
  reloadDataInvited: 0,
  loadingAll: false,
  editModal: false,
  deleteModal: false,
  impersonateModal: false,

  /* Invite/Invited Organisations */
  gridInvited: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    reloadData: 0,
    loading: false,
  },
  currentRowInvited: {},
  approveModal: false,
  inviteModal: false,
  editInvitedModal: false,
  resendModal: false,
  deleteModalInvited: false,

  currencies: [],
  modalForm: false,
  /* Register */
  registrationStep: RegistrationStep.LOADING_SCREEN,
};

const OrganisationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ORGANISATION_SET_GRID:
      return { ...state, grid: action.data };
    case ORGANISATION_SET_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case ORGANISATION_SET_DATA_ALL:
      return { ...state, dataAll: action.data };
    case ORGANISATION_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case ORGANISATION_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case ORGANISATION_SET_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.value } };
    case ORGANISATION_SET_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
      };
    case ORGANISATION_SET_LOADING_ALL:
      return { ...state, loadingAll: action.data };
    case ORGANISATION_SET_CURRENT_ROW:
      return { ...state, currentRow: action.data };
    case ORGANISATION_TOGGLE_APPROVE_MODAL:
      return { ...state, approveModal: !state.approveModal };
    case ORGANISATION_TOGGLE_INVITE_MODAL:
      return { ...state, inviteModal: !state.inviteModal };
    case ORGANISATION_TOGGLE_EDIT_MODAL:
      return { ...state, editModal: !state.editModal };
    case ORGANISATION_TOGGLE_DELETE_MODAL:
      return { ...state, deleteModal: !state.deleteModal };
    case ORGANISATION_TOGGLE_IMPERSONATE_MODAL:
      return { ...state, impersonateModal: !state.impersonateModal };
    case ORGANISATION_TOGGLE_RESEND_MODAL:
      return { ...state, resendModal: !state.resendModal };

    case ORGANISATION_SET_GRID_INVITED:
      return { ...state, gridInvited: action.data };

    case ORGANISATION_SET_DATA_INVITED:
      return {
        ...state,
        gridInvited: { ...state.gridInvited, data: action.data },
      };
    case ORGANISATION_SET_CURRENT_ROW_INVITED:
      return { ...state, currentRowInvited: action.data };
    case ORGANISATION_SET_LAST_PAGE_INVITED:
      return {
        ...state,
        gridInvited: { ...state.gridInvited, lastPage: action.data },
      };
    case ORGANISATION_SET_LOADING_INVITED:
      return {
        ...state,
        gridInvited: { ...state.gridInvited, loading: action.value },
      };
    case ORGANISATION_SET_TOTAL_ROWS_INVITED:
      return {
        ...state,
        gridInvited: { ...state.gridInvited, totalRows: action.data },
      };
    case ORGANISATION_TOGGLE_EDIT_INVITED_MODAL:
      return { ...state, editInvitedModal: !state.editInvitedModal };
    case ORGANISATION_SET_RELOAD_DATA_INVITED:
      return {
        ...state,
        gridInvited: {
          ...state.gridInvited,
          reloadData: state.gridInvited.reloadData + 1,
        },
      };
    case ORGANISATION_SET_CURRENCIES:
      return { ...state, currencies: action.data };
    case ORGANISATION_TOGGLE_MODAL_FORM:
      return { ...state, modalForm: !state.modalForm };
    case ORGANISATION_TOGGLE_DELETE_MODAL_INVITED:
      return { ...state, deleteModalInvited: !state.deleteModalInvited };
    case ORGANISATION_SET_REGISTRATION_STEP:
      return { ...state, registrationStep: action.data };
    default:
      return state;
  }
};
export default OrganisationReducer;
