/* eslint-disable no-case-declarations */
import {
  LICENSE_SET_CURRENT_ROW,
  LICENSE_TOGGLE_MODAL_FORM,
  LICENSE_TOGGLE_DELETE_MODAL,
  LICENSE_TOGGLE_RENEW_MODAL,
  LICENSE_SET_LOADING,
  LICENSE_SET_GRID,
  LICENSE_SET_TOTAL_ROWS,
  LICENSE_SET_LAST_PAGE,
  LICENSE_TOGGLE_MODAL_OPEN,
  LICENSE_SET_LOADING_APPS,
  LICENSE_SET_DATA_APPS,
  LICENSE_SET_RELOAD_DATA,
  LICENSE_UPDATE_DATA_APP,
  LICENSE_RESET_APP_IDS,
  LICENSE_SET_CURRENT_FORM_LOADING, LICENSE_SET_LOADING_ORGS, LICENSE_SET_DATA_ORGS
} from './enums/LicenseTypes';

const initialState = {
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    reloadData: 0,
    loading: false,
  },
  dataApps: [],
  orgs: [],
  currentRow: {},
  currentFormData: {
    appIds: []
  },
  currentFormLoading: false,
  loadingApps: false,
  loadingOrgs: false,
  editModal: false,
  deleteModal: false,
  renewModal: false,
  modalForm: false,
  modalOpen: false
};

const LicenseReducer = (state = initialState, action) => {
  switch (action.type) {
    case LICENSE_SET_CURRENT_ROW:
      return { ...state, currentRow: action.data };
    case LICENSE_TOGGLE_MODAL_FORM:
      return { ...state, modalForm: !state.modalForm }
    case LICENSE_TOGGLE_MODAL_OPEN:
      return { ...state, modalOpen: !state.modalOpen }
    case LICENSE_TOGGLE_DELETE_MODAL:
      return { ...state, deleteModal: !state.deleteModal }
    case LICENSE_SET_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.data } };
    case LICENSE_SET_CURRENT_FORM_LOADING:
      return { ...state, currentFormLoading: action.value };
    case LICENSE_SET_LOADING_APPS:
      return {...state, loadingApps: action.data}
    case LICENSE_SET_DATA_APPS:
      return {...state, dataApps: action.data}
    case LICENSE_UPDATE_DATA_APP:
      // toggle app id
      const currAppIds = state.currentFormData.appIds;
      return {
        ...state,
        currentFormData: {
          ...state.currentFormData,
          appIds: currAppIds.includes(action.data) ? currAppIds.filter(id => id !== action.data) : [...currAppIds, action.data]
        },
      };
    case LICENSE_RESET_APP_IDS:
      return { ...state, currentFormData: { ...state.currentFormData, appIds: []}};
    case LICENSE_SET_GRID:
      return { ...state, grid: action.data };
    case LICENSE_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case LICENSE_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case LICENSE_SET_RELOAD_DATA:
      return { ...state, grid: { ...state.grid, reloadData: state.grid.reloadData + 1 }}
    case LICENSE_TOGGLE_RENEW_MODAL:
      return { ...state, renewModal: !state.renewModal}
    case LICENSE_SET_LOADING_ORGS:
      return { ...state, loadingOrgs: true}
    case LICENSE_SET_DATA_ORGS:
      return { ...state, orgs: action.data }
    default:
      return state;
  }
}

export default LicenseReducer;
