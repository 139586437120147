/* eslint-disable no-case-declarations */
import {
  USER_GROUP_SET_DATA,
  USER_GROUP_SET_DATA_ALL,
  USER_GROUP_SET_CURRENT_ROW,
  USER_GROUP_SET_TOTAL_ROWS,
  USER_GROUP_SET_LAST_PAGE,
  USER_GROUP_SET_LOADING,
  USER_GROUP_SET_LOADING_ALL,
  USER_GROUP_SET_RELOAD_DATA,
  USER_GROUP_SET_CURRENT_FORM_DATA,
  USER_GROUP_SET_CURRENT_FORM_LOADING,
  USER_GROUP_TOGGLE_EDIT_MODAL,
  USER_GROUP_TOGGLE_DELETE_MODAL,
  USER_GROUP_CHANGE_PERM_ACTION_VALUE,
} from 'user-group/enums/UserGroupTypes';

const initialState = {
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    reloadData: 0,
    loading: false,
  },
  dataAll: [],
  currentRow: {},
  currentFormData: {},
  currentFormLoading: false,
  reloadDataInvited: 0,
  loadingAll: false,
  editModal: false,
  deleteModal: false,
};

const UserGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_GROUP_SET_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case USER_GROUP_SET_DATA_ALL:
      return { ...state, dataAll: action.data };
    case USER_GROUP_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case USER_GROUP_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case USER_GROUP_SET_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.value } };
    case USER_GROUP_SET_LOADING_ALL:
      return { ...state, loadingAll: action.value };
    case USER_GROUP_SET_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
      };
    case USER_GROUP_TOGGLE_EDIT_MODAL:
      return { ...state, editModal: !state.editModal };
    case USER_GROUP_TOGGLE_DELETE_MODAL:
      return { ...state, deleteModal: !state.deleteModal };
    case USER_GROUP_SET_CURRENT_ROW:
      return { ...state, currentRow: action.data };
    case USER_GROUP_SET_CURRENT_FORM_LOADING:
      return { ...state, currentFormLoading: action.value };
    case USER_GROUP_SET_CURRENT_FORM_DATA:
      return { ...state, currentFormData: action.data };
    case USER_GROUP_CHANGE_PERM_ACTION_VALUE:
      const { groupKey, actionKey } = action;
      const actionGroups = state.currentFormData.action_groups ?? [];
      const newState = {
        ...actionGroups,
        [groupKey]: {
          ...actionGroups[groupKey],
          actions: Object.values({
            ...actionGroups[groupKey].actions,
            [actionKey]: {
              ...actionGroups[groupKey].actions[actionKey],
              is_checked: !actionGroups[groupKey].actions[actionKey].is_checked,
            },
          }),
        },
      };

      return {
        ...state,
        currentFormData: {
          ...state.currentFormData,
          action_groups: Object.values(newState),
        },
      };

    default:
      return state;
  }
};
export default UserGroupReducer;
