export const ORGANISATION_SET_GRID = 'ORGANISATION_SET_GRID';
export const ORGANISATION_SET_DATA = 'ORGANISATION_SET_DATA';
export const ORGANISATION_SET_DATA_ALL = 'ORGANISATION_SET_DATA_ALL';
export const ORGANISATION_SET_LAST_PAGE = 'ORGANISATION_SET_LAST_PAGE';
export const ORGANISATION_SET_TOTAL_ROWS = 'ORGANISATION_SET_TOTAL_ROWS';
export const ORGANISATION_SET_CURRENT_ROW = 'ORGANISATION_SET_CURRENT_ROW';
export const ORGANISATION_SET_RELOAD_DATA = 'ORGANISATION_SET_RELOAD_DATA';
export const ORGANISATION_SET_LOADING = 'ORGANISATION_SET_LOADING';
export const ORGANISATION_SET_LOADING_ALL = 'ORGANISATION_SET_LOADING_ALL';
export const ORGANISATION_TOGGLE_APPROVE_MODAL =
  'ORGANISATION_TOGGLE_APPROVE_MODAL';
export const ORGANISATION_TOGGLE_INVITE_MODAL =
  'ORGANISATION_TOGGLE_INVITE_MODAL';
export const ORGANISATION_TOGGLE_RESEND_MODAL =
  'ORGANISATION_TOGGLE_RESEND_MODAL';
export const ORGANISATION_TOGGLE_MODAL_FORM = 'ORGANISATION_TOGGLE_MODAL_FORM';
export const ORGANISATION_TOGGLE_EDIT_MODAL = 'ORGANISATION_TOGGLE_EDIT_MODAL';
export const ORGANISATION_TOGGLE_DELETE_MODAL =
  'ORGANISATION_TOGGLE_DELETE_MODAL';
export const ORGANISATION_TOGGLE_IMPERSONATE_MODAL =
  'ORGANISATION_TOGGLE_IMPERSONATE_MODAL';
export const ORGANISATION_SET_CURRENT_ROW_INVITED =
  'ORGANISATION_SET_CURRENT_ROW_INVITED';
export const ORGANISATION_SET_DATA_INVITED = 'ORGANISATION_SET_DATA_INVITED';
export const ORGANISATION_SET_LAST_PAGE_INVITED =
  'ORGANISATION_SET_LAST_PAGE_INVITED';
export const ORGANISATION_SET_LOADING_INVITED =
  'ORGANISATION_SET_LOADING_INVITED';
export const ORGANISATION_SET_TOTAL_ROWS_INVITED =
  'ORGANISATION_SET_TOTAL_ROWS_INVITED';
export const ORGANISATION_TOGGLE_EDIT_INVITED_MODAL =
  'ORGANISATION_TOGGLE_EDIT_INVITED_MODAL';

export const ORGANISATION_SET_CURRENCIES = 'ORGANISATION_SET_CURRENCIES';

export const ORGANISATION_SET_GRID_INVITED = 'ORGANISATION_SET_GRID_INVITED';
export const ORGANISATION_SET_RELOAD_DATA_INVITED =
  'ORGANISATION_SET_RELOAD_DATA_INVITED';
export const ORGANISATION_TOGGLE_DELETE_MODAL_INVITED =
  'ORGANISATION_TOGGLE_DELETE_MODAL_INVITED';

export const ORGANISATION_SET_REGISTRATION_STEP =
  'ORGANISATION_SET_REGISTRATION_STEP';
export const OrganisationType = {
  EVERYANGLE: 1,
  DISTRIBUTOR: 2,
  TIER_1: 3,
  TIER_2: 4,
  CUSTOMER: 5,
};

export const OrganisationTypeText = {
  [OrganisationType.EVERYANGLE]: 'Everyangle',
  [OrganisationType.DISTRIBUTOR]: 'Distributor',
  [OrganisationType.TIER_1]: 'Tier 1',
  [OrganisationType.TIER_2]: 'Tier 2',
  [OrganisationType.CUSTOMER]: 'Customer',
};
