export const FLOOR_PLANS_SET_FILTERS_LOADING =
  'FLOOR_PLANS_SET_FILTERS_LOADING';

export const FLOOR_PLANS_SET_FILTERS_DATA = 'FLOOR_PLANS_SET_FILTERS_DATA';

export const FLOOR_PLANS_SET_FILTERS = 'FLOOR_PLANS_SET_FILTERS';

export const FLOOR_PLAN_SET_LOADING = 'FLOOR_PLAN_SET_LOADING';

export const FLOOR_PLAN_SET_DATA = 'FLOOR_PLAN_SET_DATA';

export const FLOOR_PLAN_SET_CURRENT_ROW = 'FLOOR_PLAN_SET_CURRENT_ROW';

export const FLOOR_PLAN_TOGGLE_CREATE_EDIT_MODAL =
  'FLOOR_PLAN_TOGGLE_CREATE_EDIT_MODAL';

export const FLOOR_PLAN_SET_GRID_DATA = 'FLOOR_PLAN_SET_GRID_DATA';

export const FLOOR_PLAN_SET_TOTAL_ROWS = 'FLOOR_PLAN_SET_TOTAL_ROWS';

export const FLOOR_PLAN_SET_LAST_PAGE = 'FLOOR_PLAN_SET_LAST_PAGE';

export const FLOOR_PLAN_TOGGLE_DELETE_MODAL = 'FLOOR_PLAN_TOGGLE_DELETE_MODAL';

export const FLOOR_PLAN_SET_RELOAD_DATA = 'FLOOR_PLAN_SET_RELOAD_DATA';

export const FLOOR_PLAN_SET_RELOAD_FILTERS = 'FLOOR_PLAN_SET_RELOAD_FILTERS';
