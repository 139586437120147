import ApiRequest from '../helpers/ApiRequest';
import ApiMethod from '../enums/ApiMethods';

export default function getRequest({
  endPoint,
  params,
  onSuccess = null,
  onFailure = null,
  onFinally = null,
}) {
  return ApiRequest({
    method: ApiMethod.GET,
    url: `${process.env.REACT_APP_API_URL}${endPoint}`,
    params,
    onSuccess,
    onFailure,
    onFinally,
  });
}
