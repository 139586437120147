export const IOC_SET_IOCPC = 'IOC_SET_IOCPC';
export const IOC_SET_DEMOGRAPHICS = 'IOC_SET_DEMOGRAPHICS';
export const IOC_SET_SIDEBAR = 'IOC_SET_SIDEBAR';
export const IOC_SET_VIEW = 'IOC_SET_VIEW';
export const IOC_SET_FILTERS = 'IOC_SET_FILTERS';
export const IOC_SET_FILTERS_SUMMARY = 'IOC_SET_FILTERS_SUMMARY';
export const IOC_SET_FILTERS_DATA = 'IOC_SET_FILTERS_DATA';
export const IOC_SET_FILTERS_LOADING = 'IOC_SET_FILTERS_LOADING';
export const IOC_SET_DATA = 'IOC_SET_DATA';
export const IOC_SET_GRID = 'IOC_SET_GRID';
export const IOC_SET_ROW = 'IOC_SET_ROW';
export const IOC_SET_LOADING = 'IOC_SET_LOADING';
export const IOC_SET_MODAL_DETAILS = 'IOC_SET_MODAL_DETAILS';
export const IOC_SET_LINECHART_COMPARISON = 'IOC_SET_LINECHART_COMPARISON';
export const IOC_SET_LINECHART_COMPARISON_LOADING =
  'IOC_SET_LINECHART_COMPARISON_LOADING';
export const IOC_SET_BARCHART_DEMOGRAPHICS = 'IOC_SET_BARCHART_DEMOGRAPHICS';
export const IOC_SET_BARCHART_DEMOGRAPHICS_LOADING =
  'IOC_SET_BARCHART_DEMOGRAPHICS_LOADING';
export const IOC_SET_GROUP_BARCHART = 'IOC_SET_GROUP_BARCHART';
export const IOC_SET_GROUP_BARCHART_LOADING = 'IOC_SET_GROUP_BARCHART_LOADING';
