import {
  ANNOUNCEMENT_SET_GRID,
  ANNOUNCEMENT_SET_DATA,
  ANNOUNCEMENT_SET_TAGS,
  ANNOUNCEMENT_SET_LAST_PAGE,
  ANNOUNCEMENT_SET_TOTAL_ROWS,
  ANNOUNCEMENT_SET_CURRENT_ROW,
  ANNOUNCEMENT_SET_RELOAD_DATA,
  ANNOUNCEMENT_SET_LOADING,
  ANNOUNCEMENT_TOGGLE_CREATE_EDIT_MODAL,
  ANNOUNCEMENT_TOGGLE_DELETE_MODAL,
  ANNOUNCEMENT_SET_FORM_DATA,
  ANNOUNCEMENT_FORM_SET_LOADING,
} from './enums/AnnouncementTypes';

export const initialState = {
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    loading: false,
    reloadData: 0,
  },

  tags: [],
  form: {
    data: {
      type: 1,
      name: '',
      send_at: null,
      tags_select: [],
      filters_json: {
        organisation_type: [],
      },
      messages: [
        {
          language: 'en-GB',
          subject: '',
          short_description: '',
          content: '',
        },
      ],
    },
    loading: true,
  },
  currentRow: {},
  createEditModal: false,
  deleteModal: false,
};

const AnnouncementReducer = (state = initialState, action) => {
  switch (action.type) {
    case ANNOUNCEMENT_SET_GRID:
      return { ...state, grid: action.data };
    case ANNOUNCEMENT_SET_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case ANNOUNCEMENT_SET_FORM_DATA:
      return { ...state, form: { ...state.form, data: action.data } };
    case ANNOUNCEMENT_FORM_SET_LOADING:
      return { ...state, form: { ...state.form, loading: action.value } };
    case ANNOUNCEMENT_SET_TAGS:
      return { ...state, tags: action.data };
    case ANNOUNCEMENT_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case ANNOUNCEMENT_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case ANNOUNCEMENT_SET_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.data } };
    case ANNOUNCEMENT_SET_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
      };
    case ANNOUNCEMENT_SET_CURRENT_ROW:
      return { ...state, currentRow: action.data };
    case ANNOUNCEMENT_TOGGLE_CREATE_EDIT_MODAL:
      return { ...state, createEditModal: !state.createEditModal };
    case ANNOUNCEMENT_TOGGLE_DELETE_MODAL:
      return { ...state, deleteModal: !state.deleteModal };
    default:
      return state;
  }
};
export default AnnouncementReducer;
