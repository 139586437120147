import {
  MI_SET_GRID,
  MI_SET_DATA,
  MI_SET_LAST_PAGE,
  MI_SET_TOTAL_ROWS,
  MI_SET_CURRENT_ROW,
  MI_SET_RELOAD_DATA,
  MI_SET_LOADING,
  MI_SET_LOADING_MODAL,
  MI_SET_TOGGLE_MODAL,
  MI_SET_TOGGLE_MODAL_VIDEO,
  MI_SET_CURRENT_ROW_VIDEO,
  MI_SET_ORGANISATIONS,
  MI_SET_SITES,
  MI_SET_CLERKS,
  MI_SET_REVIEWERS,
} from './enums/ManualInspectionTypes';

export const initialState = {
  grid: {
    has_todo: false,
    data: [],
    paginatorInfo: {
      currentPage: 1,
      lastPage: 1,
      firstItem: 0,
      lastItem: 0,
      hasMorePages: 0,
      total: 0,
    },
    loading: false,
  },
  currentRow: {},
  showModal: false,
  organisations: [],
  sites: [],
  clerks: [],
  reviewers: [],
  loadingModal: false,
};

const ManualInspectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case MI_SET_GRID:
      return { ...state, grid: action.data };
    case MI_SET_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case MI_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.value } };
    case MI_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.value } };
    case MI_SET_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
      };
    case MI_SET_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.value } };
    case MI_SET_CURRENT_ROW:
      return { ...state, currentRow: action.data };

    case MI_SET_CURRENT_ROW_VIDEO:
      return {
        ...state,
        currentRow: {
          ...state.currentRow,
          loading_video: false,
          video_url: action.value,
        },
      };
    case MI_SET_LOADING_MODAL:
      return { ...state, loadingModal: action.value };
    case MI_SET_TOGGLE_MODAL:
      return { ...state, showModal: !state.showModal };
    case MI_SET_ORGANISATIONS:
      return { ...state, organisations: action.data };
    case MI_SET_SITES:
      return { ...state, sites: action.data };
    case MI_SET_CLERKS:
      return { ...state, clerks: action.data };
    case MI_SET_REVIEWERS:
      return { ...state, reviewers: action.data };
    default:
      return state;
  }
};
export default ManualInspectionReducer;
