import GetRequest from 'app/actions/GetRequest';
import UserProfile from 'app/helpers/UserProfile';
import {
  USER_PROFILE_SET_DATA,
  USER_PROFILE_SET_LOADING,
} from 'user-profile/enums/UserProfileTypes';

const GetUserAuthData = () => (dispatch) => {
  dispatch({ type: USER_PROFILE_SET_LOADING, action: true });

  GetRequest({
    endPoint: `/user`,
    onSuccess: (data) => {
      dispatch({ type: USER_PROFILE_SET_LOADING, action: false });
      dispatch({ type: USER_PROFILE_SET_DATA, data });

      /* Store list of permission in LocalStorage */
      UserProfile.setUserData(data);
    },
    onFailure: (error) => {
      console.warn(error);
      dispatch({ type: USER_PROFILE_SET_LOADING, action: false });
    },
  });
};
export default GetUserAuthData;
