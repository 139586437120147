class UserProfile {
  constructor() {
    /* set default values */
    this.timeZone = 'Europe/Dublin';
    this.currencyCode = 'USD';
    this.language = 'en-GB';
    this.locale = null;

    /* Cached Local Storage Data */
    this.cachedData = null;
  }

  static getCircularReplacer() {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === 'object' && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      // eslint-disable-next-line consistent-return
      return value;
    };
  }

  static organisationDefaultLabels() {
    return {
      division: 'Division',
      divisions: 'Divisions',
      site: 'Site',
      site_group: 'Site Group',
      site_groups: 'Site Groups',
      sites: 'Sites',
      floor_plans: 'Floor Plans',
    };
  }

  static getUserData() {
    if (this.cachedData) {
      return this.cachedData;
    }

    const data =
      'everyangle_portal' in localStorage &&
      JSON.parse(localStorage.getItem('everyangle_portal'));
    if (!data) {
      console.warn('User Data not found.');
      return false;
    }
    this.cachedData = data;
    return data;
  }

  static setUserData(data) {
    const previousData =
      'everyangle_portal' in localStorage &&
      JSON.parse(localStorage.getItem('everyangle_portal'));

    this.cachedData = {
      is_super_admin: data.is_super_admin,
      timezone: data.timezone,
      language: data.language,
      locale: data.locale,
      organisation_type:
        data.organisation?.type ?? previousData?.organisation_type,
      organisation_alias:
        data.organisation?.alias ?? previousData?.organisation_alias,
      organisation_currency:
        data.organisation?.currency ?? previousData?.organisation_currency,
      organisation_custom_labels:
        data.organisation?.custom_labels ??
        previousData?.organisation_custom_labels,
      organisation_logo:
        data.organisation?.logo ?? previousData?.organisation_logo,
      list: data.permissions ?? previousData?.list,
      dashboards: data.dashboards,
      tour: data?.tour ?? previousData.tour,
    };
    localStorage.setItem(
      'everyangle_portal',
      JSON.stringify(this.cachedData, this.getCircularReplacer())
    );
  }

  static getUserDashboards() {
    const data = this.getUserData();
    if (!data || !data?.dashboards) {
      console.warn('Dashboard Profiles not found.');
      return false;
    }
    return data?.dashboards;
  }

  static getOrganisationType() {
    const data = this.getUserData();
    if (!data || !data?.organisation_type) {
      return null;
    }
    return data?.organisation_type;
  }

  static getOrganisationAlias() {
    const data = this.getUserData();
    if (!data || !data?.organisation_alias) {
      return null;
    }
    return data?.organisation_alias;
  }

  static getOrganisationLogo() {
    const data = this.getUserData();
    if (!data || !data?.organisation_logo) {
      return '';
    }
    return data?.organisation_logo;
  }

  static getTimeZone() {
    const data = this.getUserData();
    if (!data || typeof data.organisation_type === 'undefined') {
      return null;
    }
    return data?.timezone;
  }

  static getCustomLabel(label) {
    const data = this.getUserData();

    if (
      typeof data?.organisation_custom_labels === 'object' &&
      !Array.isArray(data?.organisation_custom_labels) &&
      data?.organisation_custom_labels !== null &&
      label in data?.organisation_custom_labels
    ) {
      return data?.organisation_custom_labels[label];
    }

    const defaults = this.organisationDefaultLabels();
    if (
      typeof defaults === 'object' &&
      !Array.isArray(defaults) &&
      defaults !== null &&
      label in defaults
    ) {
      return defaults[label];
    }
    return label;
  }

  static canPlayIocTour() {
    const data = this.getUserData();
    return !data || !data?.tour || !data?.tour?.ioc;
  }

  static getLanguage() {
    const data = this.getUserData();
    if (!data || typeof data.language === 'undefined') {
      return this.language;
    }
    return data?.language;
  }

  static getLocale() {
    let locale = 'en-GB';
    const data = this.getUserData();

    if (data || typeof data.locale !== 'undefined') {
      locale = data?.locale;
    }

    try {
      // Make sure the locale is valid
      const obj = new Intl.DateTimeFormat(locale);
      this.locale = locale;
      return this.locale;
    } catch (ex) {
      this.locale = 'en-GB';
      return 'en-GB';
    }
  }

  static getCurrencyCode() {
    const data = this.getUserData();
    if (!data || typeof data.organisation_currency === 'undefined') {
      return this.currencyCode || 'USD';
    }
    return data.organisation_currency || 'USD';
  }

  static toUTC(date) {
    return `${date.replace(' ', 'T')}.000Z`;
  }

  static printDate(date, locale = null) {
    this.locale = locale ?? this.getLocale();
    if (!this.timeZone) {
      this.timeZone = this.getTimeZone();
    }
    return new Intl.DateTimeFormat(this.locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      timeZone: this.timeZone,
    }).format(date instanceof Date ? date : new Date(date));
  }

  static printWeekDay(date, locale = null, type = 'short') {
    this.locale = locale ?? this.getLocale();

    /*
     * If the date parameter is a date object, we cannot use Intl.DateTimeFormat
     * with timeZone as it may change the date by day depending on the user's time zone in the operating system.
     */
    if (date instanceof Date) {
      return new Intl.DateTimeFormat(this.locale, {
        weekday: type,
      }).format(date);
    }
    return new Intl.DateTimeFormat(this.locale, {
      weekday: type,
    }).format(new Date(`${date}T00:00:00`));
  }

  // Extract Date Format from Intl Locale
  static getDateFormat(locale = null) {
    this.locale = locale ?? this.getLocale();

    const year = 2021;
    const month = 12;
    const day = 11;
    const date = new Date(year, month - 1, day);
    const formattedDate = new Intl.DateTimeFormat(this.locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date);
    return formattedDate
      .replace(`${year}`, 'yyyy')
      .replace(`${month}`, 'MM')
      .replace(`${day}`, 'dd');
  }

  // Extract Date time Format from Intl Locale
  static getDateTimeFormat(locale = null) {
    this.locale = locale ?? this.getLocale();

    const year = 2021;
    const month = 12;
    const day = 11;
    const hour = 10;
    const minute = 15;
    const date = new Date(year, month - 1, day, hour, minute);
    const formattedDate = new Intl.DateTimeFormat(this.locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false,
    }).format(date);

    return formattedDate
      .replace(`${year}`, 'yyyy')
      .replace(`${month}`, 'MM')
      .replace(`${day}`, 'dd')
      .replace(`${hour}`, 'HH')
      .replace(`${minute}`, 'mm')
      .replace(',', '');
  }

  static printDateTimeWithoutTimeZone(date, locale = null) {
    this.locale = locale ?? this.getLocale();

    return new Intl.DateTimeFormat(this.locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    })
      .format(date instanceof Date ? date : new Date(date.replace(' ', 'T')))
      .replace(',', '');
  }

  static printDateWithoutTimeZone(date, locale = null) {
    this.locale = locale ?? this.getLocale();
    /*
     * If the date parameter is a date object, we cannot use Intl.DateTimeFormat
     * with timeZone as it may change the date by day depending on the user's time zone in the operating system.
     */
    return new Intl.DateTimeFormat(this.locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(date instanceof Date ? date : new Date(`${date}T00:00:00`));
  }

  static printMonthYear(date, locale = null) {
    this.locale = locale ?? this.getLocale();
    return new Intl.DateTimeFormat(this.locale, {
      month: 'short',
      year: 'numeric',
    }).format(new Date(date));
  }

  static printTimeWithoutTimeZone(date, locale = null, withSeconds = false) {
    this.locale = locale ?? this.getLocale();
    const format = withSeconds
      ? {
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        }
      : {
          hour: '2-digit',
          minute: '2-digit',
        };
    return new Intl.DateTimeFormat(this.locale, format).format(
      date instanceof Date ? date : date.replace(' ', 'T')
    );
  }

  static printDateTime(dateTime, locale = null) {
    /* sanitize */
    if (!dateTime) return '';

    if (!this.timeZone) {
      this.timeZone = this.getTimeZone();
    }

    this.locale = locale ?? this.getLocale();

    return new Intl.DateTimeFormat(this.locale, {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZone: this.timeZone,
    })
      .format(
        dateTime instanceof Date ? dateTime : new Date(this.toUTC(dateTime))
      )
      .replace(',', '');
  }

  static printNumber(value, locale = null) {
    this.locale = locale ?? this.getLocale();
    return new Intl.NumberFormat(this.locale).format(value);
  }

  static printCurrency(value) {
    if (value === null || value === undefined || value === '') {
      return '-';
    }

    this.currencyCode = this.getCurrencyCode();
    this.locale = this.getLocale();
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currencyCode,
      currencyDisplay: 'narrowSymbol',
    }).format(value);
  }

  static printCurrencyTrend(value) {
    this.currencyCode = this.getCurrencyCode();
    this.locale = this.getLocale();
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currencyCode,
      currencyDisplay: 'narrowSymbol',
      signDisplay: 'exceptZero',
    }).format(value);
  }

  static printCurrencyCompact(value) {
    if (!value) return '--';
    this.currencyCode = this.getCurrencyCode();
    this.locale = this.getLocale();
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currencyCode,
      currencyDisplay: 'narrowSymbol',
      notation: 'compact',
      compactDisplay: 'short',
    }).format(value);
  }

  static printNumberCompact(value) {
    if (!value) return '--';
    this.locale = this.getLocale();
    return new Intl.NumberFormat(this.locale, {
      notation: 'compact',
      compactDisplay: 'short',
    }).format(value);
  }

  static printCurrencyCompactTrend(value) {
    this.currencyCode = this.getCurrencyCode();
    this.locale = this.getLocale();
    return new Intl.NumberFormat(this.locale, {
      style: 'currency',
      currency: this.currencyCode,
      currencyDisplay: 'narrowSymbol',
      signDisplay: 'exceptZero',
      notation: 'compact',
      compactDisplay: 'short',
    }).format(value);
  }

  static printCurrencySymbol() {
    return (0)
      .toLocaleString('en', {
        style: 'currency',
        currency: this.getCurrencyCode(),
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })
      .replace(/\d/g, '')
      .trim();
  }
}

export default UserProfile;
