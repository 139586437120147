import {
  FMD_SET_VIEW,
  FMD_SET_FILTERS,
  FMD_SET_DATA,
  FMD_SET_SIDEBAR,
  FMD_SET_LOADING,
  FMD_SET_FILTERS_DATA,
  FMD_SET_FILTERS_LOADING,
  FMD_SET_MODAL_LIST,
  FMD_SET_MODAL_LIST_LOCATIONS,
} from './enums/DashboardTypes';

export const initialState = {
  sitesOnline: 97,
  sitesDeployed: 97,
  filters: {
    loading: true,
    data: {
      divisions: [],
      site_groups: [],
      sites: [],
      locations: [],
    },
    selected: [],
  },
  data: {
    selected_date: {},
    totals: {
      current: {
        total: 0,
        total_of_interest: 0,
      },
      previous: {
        total: 0,
        total_of_interest: 0,
      },
    },
    divisions: null,
    site_groups: null,
    sites: null,
    cameras: null,
    locations: null,
    line_chart: [],
  },
  sidebar: false,
  loading: false,
  modalList: false,
  modalListLocations: false,
};

const DashBoardFMDReducer = (state = initialState, action) => {
  switch (action.type) {
    case FMD_SET_VIEW:
      return { ...state, filters: { ...state.filters, view: action.data } };
    case FMD_SET_DATA:
      return { ...state, data: action.data };
    case FMD_SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, selected: action.data },
      };
    case FMD_SET_FILTERS_DATA:
      return { ...state, filters: { ...state.filters, data: action.data } };
    case FMD_SET_FILTERS_LOADING:
      return { ...state, filters: { ...state.filters, loading: action.value } };
    case FMD_SET_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case FMD_SET_LOADING:
      return { ...state, loading: action.value };
    case FMD_SET_MODAL_LIST:
      return { ...state, modalList: !state.modalList };
    case FMD_SET_MODAL_LIST_LOCATIONS:
      return { ...state, modalListLocations: !state.modalListLocations };
    default:
      return state;
  }
};
export default DashBoardFMDReducer;
