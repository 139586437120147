export const ANNOUNCEMENT_SET_GRID = 'ANNOUNCEMENT_SET_GRID';
export const ANNOUNCEMENT_SET_DATA = 'ANNOUNCEMENT_SET_DATA';
export const ANNOUNCEMENT_SET_FORM_DATA = 'ANNOUNCEMENT_SET_FORM_DATA';
export const ANNOUNCEMENT_SET_TAGS = 'ANNOUNCEMENT_SET_TAGS';
export const ANNOUNCEMENT_SET_LAST_PAGE = 'ANNOUNCEMENT_SET_LAST_PAGE';
export const ANNOUNCEMENT_SET_TOTAL_ROWS = 'ANNOUNCEMENT_SET_TOTAL_ROWS';
export const ANNOUNCEMENT_SET_CURRENT_ROW = 'ANNOUNCEMENT_SET_CURRENT_ROW';
export const ANNOUNCEMENT_SET_RELOAD_DATA = 'ANNOUNCEMENT_SET_RELOAD_DATA';
export const ANNOUNCEMENT_SET_LOADING = 'ANNOUNCEMENT_SET_LOADING';
export const ANNOUNCEMENT_FORM_SET_LOADING = 'ANNOUNCEMENT_FORM_SET_LOADING';

export const ANNOUNCEMENT_TOGGLE_CREATE_EDIT_MODAL =
  'ANNOUNCEMENT_TOGGLE_CREATE_EDIT_MODAL';
export const ANNOUNCEMENT_TOGGLE_DELETE_MODAL =
  'ANNOUNCEMENT_TOGGLE_DELETE_MODAL';

export const AnnouncementType = {
  ANNOUNCEMENT: 1,
  NEW_RELEASE: 2,
  ALERT: 3,
  ACCOUNT: 4,
};

export const AnnouncementTypeText = {
  [AnnouncementType.ANNOUNCEMENT]: 'Announcement',
  [AnnouncementType.NEW_RELEASE]: 'New Release',
  [AnnouncementType.ALERT]: 'Alert',
  [AnnouncementType.ACCOUNT]: 'Account',
};

export const AnnouncementTypeTextColor = {
  [AnnouncementType.ANNOUNCEMENT]: 'text-undefined',
  [AnnouncementType.NEW_RELEASE]: 'text-undefined',
  [AnnouncementType.ALERT]: 'text-danger',
  [AnnouncementType.ACCOUNT]: 'text-warning',
};
