export const DEPARTMENT_SET_DATA = 'DEPARTMENT_SET_DATA';
export const DEPARTMENT_SET_DATA_ALL = 'DEPARTMENT_SET_DATA_ALL';
export const DEPARTMENT_SET_LAST_PAGE = 'DEPARTMENT_SET_LAST_PAGE';
export const DEPARTMENT_SET_TOTAL_ROWS = 'DEPARTMENT_SET_TOTAL_ROWS';
export const DEPARTMENT_SET_CURRENT_ROW = 'DEPARTMENT_SET_CURRENT_ROW';
export const DEPARTMENT_SET_CURRENT_FORM_DATA =
  'DEPARTMENT_SET_CURRENT_FORM_DATA';
export const DEPARTMENT_SET_RELOAD_DATA = 'DEPARTMENT_SET_RELOAD_DATA';
export const DEPARTMENT_SET_LOADING = 'DEPARTMENT_SET_LOADING';
export const DEPARTMENT_SET_LOADING_ALL = 'DEPARTMENT_SET_LOADING_ALL';
export const DEPARTMENT_TOGGLE_RESEND_MODAL = 'DEPARTMENT_TOGGLE_RESEND_MODAL';
export const DEPARTMENT_TOGGLE_EDIT_MODAL = 'DEPARTMENT_TOGGLE_EDIT_MODAL';
export const DEPARTMENT_TOGGLE_DELETE_MODAL = 'DEPARTMENT_TOGGLE_DELETE_MODAL';
