export const USER_SET_DATA = 'USER_SET_DATA';
export const USER_SET_DATA_ALL = 'USER_SET_DATA_ALL';
export const USER_SET_LAST_PAGE = 'USER_SET_LAST_PAGE';
export const USER_SET_TOTAL_ROWS = 'USER_SET_TOTAL_ROWS';
export const USER_SET_CURRENT_ROW = 'USER_SET_CURRENT_ROW';
export const USER_SET_CURRENT_FORM_DATA = 'USER_SET_CURRENT_FORM_DATA';
export const USER_SET_CURRENT_FORM_LOADING = 'USER_SET_CURRENT_FORM_LOADING';
export const USER_SET_RELOAD_DATA = 'USER_SET_RELOAD_DATA';
export const USER_SET_LOADING = 'USER_SET_LOADING';
export const USER_SET_LOADING_ALL = 'USER_SET_LOADING_ALL';
export const USER_TOGGLE_RESEND_MODAL = 'USER_TOGGLE_RESEND_MODAL';
export const USER_TOGGLE_EDIT_MODAL = 'USER_TOGGLE_EDIT_MODAL';
export const USER_TOGGLE_UNLOCK_MODAL = 'USER_TOGGLE_UNLOCK_MODAL';
export const USER_TOGGLE_DELETE_MODAL = 'USER_TOGGLE_DELETE_MODAL';
export const USER_CHANGE_PERM_ACTION_VALUE = 'USER_CHANGE_PERM_ACTION_VALUE';
export const USER_SET_REGISTRATION_STEP = 'USER_SET_REGISTRATION_STEP';
export const USER_TOGGLE_MFA_DISABLE_MODAL = 'USER_TOGGLE_MFA_DISABLE_MODAL';
