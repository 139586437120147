import UserProfile from './UserProfile';

class AuthToken {
  /**
   * Authenticate a user. Save a token string in Local Storage
   *
   * @param {string} token
   */

  static saveAuthToken(token) {
    localStorage.setItem('everyangle_token', token);
  }

  static saveRefreshToken(token) {
    localStorage.setItem('everyangle_refresh_token', token);
  }

  static saveExpireInToken(token) {
    localStorage.setItem('everyangle_expire_token', token);
  }

  /**
   * Authenticate a user. Save user id and account id in Local Storage
   *
   * @param {string} token
   */
  static referenceUser(userId, accountId) {
    localStorage.setItem('user_id', userId);
    localStorage.setItem('account_id', accountId);
  }

  /**
   * Check if a user is authenticated - check if a token is saved in Local Storage
   *
   * @returns {boolean}
   */
  static isUserAuthenticated() {
    const isUserAuthenticated =
      localStorage.getItem('everyangle_token') !== null;
    return isUserAuthenticated;
  }

  /**
   * Deauthenticate a user. Remove a token from Local Storage.
   *
   */
  static deleteToken() {
    localStorage.removeItem('everyangle_token');
  }

  /**
   * Remove The Refresh Token from Local Storage.
   *
   */
  static deleteRefreshToken() {
    localStorage.removeItem('everyangle_refresh_token');
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */

  static getToken() {
    return localStorage.getItem('everyangle_token') || '';
  }

  /**
   * Get a token value.
   *
   * @returns {string}
   */
  static getRefreshToken() {
    return localStorage.getItem('everyangle_refresh_token') || '';
  }

  static getUserId() {
    return localStorage.getItem('user_id');
  }

  static getAccountId() {
    return localStorage.getItem('account_id');
  }
}

export default AuthToken;
