import AuthStep from 'auth/enums/AuthStep';
import {
  AUTH_REQUEST,
  AUTH_REQ_SUCCESS,
  AUTH_REQ_FAILURE,
  AUTH_SHOW_ALERT,
  AUTH_HIDE_ALERT,
  AUTH_SHOW_2FA_SCREEN,
  AUTH_SHOW_MFA_SCREEN,
  AUTH_SHOW_FORM_SCREEN,
  AUTH_SHOW_MSG_SCREEN,
  AUTH_SHOW_ERROR_MSG_SCREEN,
  AUTH_CLEAN_DATA,
} from 'auth/enums/AuthTypes';

const initialState = {
  currentStep: AuthStep.FORM_SCREEN,
  loading: false,
  alert: 0,
  alertType: 'danger',
  alertMsg: null,
};

// eslint-disable-next-line no-unused-vars
const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SHOW_2FA_SCREEN:
      return { ...state, currentStep: AuthStep.TWO_FA_SCREEN };
    case AUTH_SHOW_MFA_SCREEN:
      return { ...state, currentStep: AuthStep.MFA_SCREEN };
    case AUTH_SHOW_MSG_SCREEN:
      return { ...state, currentStep: AuthStep.CONFIRMATION_MSG_SCREEN };
    case AUTH_SHOW_ERROR_MSG_SCREEN:
      return { ...state, currentStep: AuthStep.ERROR_MSG_SCREEN };
    case AUTH_SHOW_FORM_SCREEN:
      return { ...state, currentStep: AuthStep.FORM_SCREEN };
    case AUTH_CLEAN_DATA:
      return { ...state, password: '', login: '' };
    case AUTH_REQUEST:
      return { ...state, loading: true };
    case AUTH_SHOW_ALERT:
      return {
        ...state,
        alert: 1,
        alertType: action.alertType,
        alertMsg: action.message,
      };
    case AUTH_HIDE_ALERT:
      return { ...state, alert: 0, alertMsg: null };
    case AUTH_REQ_SUCCESS:
    case AUTH_REQ_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};
export default AuthReducer;
