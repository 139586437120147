import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import GetUserAuthData from 'user-profile/actions/GetUserAuthDataAction';
import ChangeEmailConfirmationPage from 'user-profile/components/ChangeEmailConfirmationPage';
import ChangeEmailVerificationPage from 'user-profile/components/ChangeEmailVerificationPage';
import { USER_PROFILE_SET_LOADING } from 'user-profile/enums/UserProfileTypes';
import {
  MatomoProvider,
  createInstance,
  useMatomo,
} from '@datapunt/matomo-tracker-react';
import './assets/scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse" />
  </div>
);

// Containers
const TheLayout = React.lazy(() =>
  import('app/components/containers/TheLayout')
);

const Login = React.lazy(() => import('auth/components/LoginForm'));
const ResetPassword = React.lazy(() =>
  import('auth/components/ResetPasswordForm')
);
const SubmitResetPasswordForm = React.lazy(() =>
  import('auth/components/SubmitResetPasswordForm')
);
const RegisterOrganisation = React.lazy(() =>
  import('organisations/components/RegisterOrganisation')
);
const InvitedUserForm = React.lazy(() =>
  import('user/components/InvitedUserForm')
);
const Page404 = React.lazy(() => import('app/public/Page404'));
const Page500 = React.lazy(() => import('app/public/Page500'));

const App = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.userProfile);

  const instance =
    process.env.NODE_ENV === 'production'
      ? createInstance({
          urlBase: 'https://everyangle.matomo.cloud/',
          siteId: 2,
        })
      : null;

  useEffect(() => {
    if (
      window.location.pathname === '/login' ||
      window.location.pathname.includes('/pub/')
    ) {
      // login
      dispatch({ type: USER_PROFILE_SET_LOADING, action: false });
    } else {
      /* Load Authenticated User Data */
      dispatch(GetUserAuthData());
    }
  }, []);

  useEffect(() => {
    if (userData?.data?.first_name) {
      // eslint-disable-next-line no-underscore-dangle, no-multi-assign
      const _paq = (window._paq = window._paq || []);
      _paq.push([
        'setUserId',
        `${userData?.data?.first_name} ${userData?.data?.last_name} - ${userData?.data?.organisation?.name}`,
      ]);
    }
  }, [userData?.data]);

  return (
    <>
      <MatomoProvider value={instance}>
        {userData.loading ? (
          <div className="loader">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <BrowserRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route
                  exact
                  path="/login"
                  name="Login Page"
                  render={(props) => <Login {...props} />}
                />
                <Route
                  exact
                  path="/reset"
                  name="Reset Password Page"
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path="/reset/email/:email"
                  name="Reset Password Page"
                  render={(props) => <ResetPassword {...props} />}
                />
                <Route
                  exact
                  path="/pub/reset/token/:token/email/:email"
                  name="Reset Password Page"
                  render={(props) => <SubmitResetPasswordForm {...props} />}
                />
                <Route
                  exact
                  path="/pub/verify-change-email/token/:token/email/:email"
                  name="Change Email Verification Page"
                  render={(props) => <ChangeEmailVerificationPage {...props} />}
                />
                <Route
                  exact
                  path="/pub/change-email/token/:token/email/:email"
                  name="Change Email Page"
                  render={(props) => <ChangeEmailConfirmationPage {...props} />}
                />
                <Route
                  exact
                  path="/pub/register/token/:token/email/:email"
                  name="Organisation and User Register Page"
                  render={(props) => <RegisterOrganisation {...props} />}
                />
                <Route
                  exact
                  path="/pub/active-invited-user/token/:token/email/:email"
                  name="Organisation and User Register Page"
                  render={(props) => <InvitedUserForm {...props} />}
                />
                <Route
                  exact
                  path="/404"
                  name="Page 404"
                  render={(props) => <Page404 {...props} />}
                />
                <Route
                  exact
                  path="/500"
                  name="Page 500"
                  render={(props) => <Page500 {...props} />}
                />
                <Route
                  path="/"
                  name="Home"
                  render={(props) => <TheLayout {...props} />}
                />
              </Switch>
            </React.Suspense>
          </BrowserRouter>
        )}
      </MatomoProvider>
    </>
  );
};

export default App;
