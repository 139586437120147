import {
  MAC_SET_DATA,
  MAC_SET_SIDEBAR,
  MAC_SET_FILTERS,
  MAC_SET_FILTERS_SUMMARY,
  MAC_SET_FILTERS_DATA,
  MAC_SET_FILTERS_LOADING,
  MAC_SET_ROW,
  MAC_SET_LOADING,
  MAC_SET_MODAL_FORM,
  MAC_SET_FORM,
  MAC_SET_GRID_LOADING,
  MAC_SET_GRID_RELOAD_DATA,
  MAC_SET_GRID,
  MAC_SET_GRID_DATA,
} from './enums/DashboardTypes';

export const initialState = {
  filters: {
    loading: false,
    data: {
      sites: [],
    },
    selected: {},
    summary: [],
  },
  data: {
    sites_deployed: 5,
    sites_online: 5,
    selected_date: {
      current: {
        start: '',
        end: '',
      },
      previous: {
        start: '',
        end: '',
      },
      next: {
        start: '',
        end: '',
      },
    },
    totals: {
      previous: {
        entrances_site: 0,
        entrances_zone: 0,
        conversion: 0,
        engagement_time: 0,
        queue_time: 0,
        occupancy_engagement: 0,
        occupancy_queue: 0,
      },
      current: {
        entrances_site: 0,
        entrances_zone: 0,
        conversion: 0,
        engagement_time: 0,
        queue_time: 0,
        occupancy_engagement: 0,
        occupancy_queue: 0,
      },

      next: {
        entrances_site: 0,
        entrances_zone: 0,
        conversion: 0,
        engagement_time: 0,
        queue_time: 0,
        occupancy_engagement: 0,
        occupancy_queue: 0,
      },
    },
    line_chart: {
      previous: [],
      current: [],
      next: [],
    },
    sites: [],
  },
  grid: {
    data: [],
    paginatorInfo: {
      currentPage: 0,
      lastPage: 0,
      firstItem: 0,
      lastItem: 0,
      hasMorePages: false,
      total: 0,
    },
  },
  row: {},
  sidebar: false,
  loading: false,
  loadingGrid: false,
  reloadDataGrid: 0,
  modal: false,
  form: false,
};
const DashBoardMACReducer = (state = initialState, action) => {
  switch (action.type) {
    case MAC_SET_DATA:
      return { ...state, data: action.data };
    case MAC_SET_ROW:
      return { ...state, row: action.data };
    case MAC_SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, selected: action.data },
      };
    case MAC_SET_FILTERS_SUMMARY:
      return {
        ...state,
        filters: { ...state.filters, summary: action.data },
      };
    case MAC_SET_FILTERS_DATA:
      return { ...state, filters: { ...state.filters, data: action.data } };
    case MAC_SET_FILTERS_LOADING:
      return { ...state, filters: { ...state.filters, loading: action.value } };
    case MAC_SET_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case MAC_SET_LOADING:
      return { ...state, loading: action.value };
    case MAC_SET_MODAL_FORM:
      return { ...state, modal: !state.modal };
    case MAC_SET_FORM:
      return {
        ...state,
        form: !state.form,
        row: !state.form ? state.row : {}, // clear row on form close
      };
    case MAC_SET_GRID:
      return { ...state, grid: action.data };
    case MAC_SET_GRID_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case MAC_SET_GRID_LOADING:
      return { ...state, loadingGrid: action.value };
    case MAC_SET_GRID_RELOAD_DATA:
      return {
        ...state,
        reloadDataGrid: state.reloadDataGrid + 1,
      };
    default:
      return state;
  }
};
export default DashBoardMACReducer;
