/* eslint-disable no-param-reassign */
import axios from 'axios';
import AuthToken from './AuthToken';

const lastTokenRefresh = null;
let WaitingReply = false;
let pendingRequests = [];

const setNewToken = (response) => {
  /* Update new token in local storage */
  AuthToken.saveAuthToken(response.data.access_token);
  AuthToken.saveRefreshToken(response.data.refresh_token);
  AuthToken.saveExpireInToken(response.data.expires_in);
};

const processQueue = (error, token = null) => {
  pendingRequests.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve({ data: { access_token: token } });
    }
  });

  pendingRequests = [];
  WaitingReply = false;
};

export const getOAuthRefreshToken = () => {
  const newRefreshToken = AuthToken.getRefreshToken();

  if (WaitingReply) {
    return new Promise((resolve, reject) => {
      pendingRequests.push({ resolve, reject });
    });
  }

  WaitingReply = true;

  return new Promise((resolve, reject) =>
    axios
      .post(`${process.env.REACT_APP_API_URL}/refresh-token`, {
        refresh_token: newRefreshToken,
      })
      .then((resp) => {
        if (resp) {
          processQueue(null, resp?.data?.access_token);
          setNewToken(resp);
        }
        resolve(resp);
      })
      .catch((error) => {
        console.warn(error);
        processQueue(error);
        reject(error);
      })
  );
};
