export const USER_GROUP_SET_DATA = 'USER_GROUP_SET_DATA';
export const USER_GROUP_SET_DATA_ALL = 'USER_GROUP_SET_DATA_ALL';
export const USER_GROUP_SET_LAST_PAGE = 'USER_GROUP_SET_LAST_PAGE';
export const USER_GROUP_SET_TOTAL_ROWS = 'USER_GROUP_SET_TOTAL_ROWS';
export const USER_GROUP_SET_CURRENT_ROW = 'USER_GROUP_SET_CURRENT_ROW';
export const USER_GROUP_SET_CURRENT_FORM_DATA =
  'USER_GROUP_SET_CURRENT_FORM_DATA';
export const USER_GROUP_SET_CURRENT_FORM_LOADING =
  'USER_GROUP_SET_CURRENT_FORM_LOADING';
export const USER_GROUP_SET_RELOAD_DATA = 'USER_GROUP_SET_RELOAD_DATA';
export const USER_GROUP_SET_LOADING = 'USER_GROUP_SET_LOADING';
export const USER_GROUP_SET_LOADING_ALL = 'USER_GROUP_SET_LOADING_ALL';
export const USER_GROUP_TOGGLE_RESEND_MODAL = 'USER_GROUP_TOGGLE_RESEND_MODAL';
export const USER_GROUP_TOGGLE_EDIT_MODAL = 'USER_GROUP_TOGGLE_EDIT_MODAL';
export const USER_GROUP_TOGGLE_DELETE_MODAL = 'USER_GROUP_TOGGLE_DELETE_MODAL';
export const USER_GROUP_CHANGE_PERM_ACTION_VALUE =
  'USER_GROUP_CHANGE_PERM_ACTION_VALUE';
