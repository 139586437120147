export const CRE_SET_SIDEBAR = 'CRE_SET_SIDEBAR';
export const CRE_SET_VIEW = 'CRE_SET_VIEW';
export const CRE_SET_FILTERS = 'CRE_SET_FILTERS';
export const CRE_SET_FILTERS_DATA = 'CRE_SET_FILTERS_DATA';
export const CRE_SET_FILTERS_LOADING = 'CRE_SET_FILTERS_LOADING';
export const CRE_SET_FILTERS_SUMMARY = 'CRE_SET_FILTERS_SUMMARY';
export const CRE_SET_DATA = 'CRE_SET_DATA';
export const CRE_SET_LOADING = 'CRE_SET_LOADING';
export const CRE_SET_SITES_DATA = 'CRE_SET_SITES_DATA';
export const CRE_SET_SITES_LOADING = 'CRE_SET_SITES_LOADING';
export const CRE_SET_AGGREGATE_CHART_DATA = 'CRE_SET_AGGREGATE_CHART_DATA';
export const CRE_SET_AGGREGATE_CHART_DATA_LOADING =
  'CRE_SET_AGGREGATE_CHART_DATA_LOADING';
