import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CRow,
} from '@coreui/react';

import CIcon from '@coreui/icons-react';
import { useDispatch } from 'react-redux';
import everyangleLogo from 'app/assets/images/everyangle-logo-blue.png';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import ApplyVerifyChangeEmailAction from '../actions/ApplyVerifyChangeEmailAction';

const InvalidHashMessage = ({ handleGoToLoginBtn }) => (
  <>
    <h2 className="text-center pb-2">Sorry, this link is expired </h2>

    <p className="text-center">
      <CButton color="link" className="px-0" onClick={handleGoToLoginBtn}>
        Go to login page
      </CButton>{' '}
      or contact your administrator
    </p>
  </>
);

const SuccessPasswordResetMessage = ({ handleGoToLoginBtn }) => (
  <>
    <h2 className="text-center pb-2">
      The verification link has been sent to your new email.
    </h2>
    <p className="text-center">
      Should you fail to receive it within the next 15 minutes, please make sure
      that you provided a valid email address and check your spam folder.
    </p>
    <p className="text-center pb-4">
      {' '}
      Should further assistance be needed, please contact your administrator.
    </p>
  </>
);
const ChangeEmailVerificationPage = () => {
  const { email, token } = useParams();
  const [currentStep, setCurrentStep] = useState(0);
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackPageView } = useMatomo();

  const handleGoToLoginBtn = () => {
    history.push('/login');
  };
  useEffect(() => {
    const onSuccess = () => {
      setCurrentStep(1);
    };
    const onError = () => {
      setCurrentStep(2);
    };

    dispatch(ApplyVerifyChangeEmailAction(email, token, onSuccess, onError));
    trackPageView();
  }, []);

  /* Determinate current showing component */
  let CurrentComponent = ChangeEmailVerificationPage;
  if (currentStep === 1) CurrentComponent = SuccessPasswordResetMessage;
  else if (currentStep === 2) CurrentComponent = InvalidHashMessage;

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol className="col-12" lg="5">
            <div className="text-center mb-3">
              <CIcon
                className="justify-content-center"
                src={everyangleLogo}
                height={90}
              />
            </div>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  {currentStep === 0 ? (
                    <>
                      <div className="spinner-border spinner-border-sm ml-2" />{' '}
                      Loading...
                    </>
                  ) : (
                    <CurrentComponent handleGoToLoginBtn={handleGoToLoginBtn} />
                  )}
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default ChangeEmailVerificationPage;
