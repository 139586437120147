export const AUTH_SHOW_FORM_SCREEN = 'AUTH_SHOW_FORM_SCREEN';
export const AUTH_SHOW_2FA_SCREEN = 'AUTH_SHOW_2FA_SCREEN';
export const AUTH_SHOW_MFA_SCREEN = 'AUTH_SHOW_MFA_SCREEN';
export const AUTH_CLEAN_DATA = 'AUTH_CLEAN_DATA';
export const AUTH_REQUEST = 'AUTH_REQUEST';
export const AUTH_REQ_SUCCESS = 'AUTH_REQ_SUCCESS';
export const AUTH_REQ_FAILURE = 'AUTH_REQ_FAILURE';
export const AUTH_SHOW_ALERT = 'AUTH_SHOW_ALERT';
export const AUTH_HIDE_ALERT = 'AUTH_HIDE_ALERT';
export const AUTH_SHOW_MSG_SCREEN = 'AUTH_SHOW_MSG_SCREEN';
export const AUTH_SHOW_ERROR_MSG_SCREEN = 'AUTH_SHOW_ERROR_MSG_SCREEN';
