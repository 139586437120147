import {
  SITE_SET_GRID,
  SITE_SET_DATA,
  SITE_SET_DATA_ALL,
  SITE_SET_LAST_PAGE,
  SITE_SET_TOTAL_ROWS,
  SITE_SET_CURRENT_ROW,
  SITE_SET_RELOAD_DATA,
  SITE_SET_LOADING,
  SITE_TOGGLE_CREATE_EDIT_MODAL,
  SITE_TOGGLE_DELETE_MODAL,
  SITE_SET_LOADING_ALL,
  SITE_SET_SIZES_GRID,
  SITE_SET_SIZES_DATA,
  SITE_SET_SIZES_DATA_ALL,
  SITE_SET_SIZES_LAST_PAGE,
  SITE_SET_SIZES_TOTAL_ROWS,
  SITE_SET_SIZES_LOADING,
  SITE_SET_SIZES_RELOAD_DATA,
  SITE_SIZES_TOGGLE_CREATE_EDIT_MODAL,
  SITE_SIZES_TOGGLE_DELETE_MODAL,
  SITE_SET_SIZES_CURRENT_ROW,
  SITE_SET_FORMATS_GRID,
  SITE_SET_FORMATS_DATA,
  SITE_SET_FORMATS_DATA_ALL,
  SITE_SET_FORMATS_LAST_PAGE,
  SITE_SET_FORMATS_TOTAL_ROWS,
  SITE_SET_FORMATS_LOADING,
  SITE_SET_FORMATS_RELOAD_DATA,
  SITE_FORMATS_TOGGLE_CREATE_EDIT_MODAL,
  SITE_FORMATS_TOGGLE_DELETE_MODAL,
  SITE_SET_FORMATS_CURRENT_ROW,
} from './enums/SiteTypes';

const initialState = {
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    reloadData: 0,
    loading: false,
  },
  dataAll: [],
  currentRow: {},
  createEditModal: false,
  deleteModal: false,
  loadingAll: false,
  sizes: {
    grid: {
      data: [],
      lastPage: 0,
      totalRows: 0,
      reloadData: 0,
      loading: false,
    },
    createEditModal: false,
    deleteModal: false,
    dataAll: [],
    currentRow: {},
  },
  formats: {
    grid: {
      data: [],
      lastPage: 0,
      totalRows: 0,
      reloadData: 0,
      loading: false,
    },
    createEditModal: false,
    deleteModal: false,
    dataAll: [],
    currentRow: {},
  },
};

const SiteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SITE_SET_GRID:
      return { ...state, grid: action.data };
    case SITE_SET_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case SITE_SET_DATA_ALL:
      return { ...state, dataAll: action.data };
    case SITE_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case SITE_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case SITE_SET_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.value } };
    case SITE_SET_LOADING_ALL:
      return { ...state, loadingAll: action.value };
    case SITE_SET_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
      };
    case SITE_SET_CURRENT_ROW:
      return { ...state, currentRow: action.data };
    case SITE_TOGGLE_CREATE_EDIT_MODAL:
      return { ...state, createEditModal: !state.createEditModal };
    case SITE_TOGGLE_DELETE_MODAL:
      return { ...state, deleteModal: !state.deleteModal };

    case SITE_SET_SIZES_GRID:
      return {
        ...state,
        sizes: {
          ...state.sizes,
          grid: action.data,
        },
      };
    case SITE_SET_SIZES_DATA:
      return {
        ...state,
        sizes: {
          ...state.sizes,
          grid: { ...state.sizes.grid, data: action.data },
        },
      };
    case SITE_SET_SIZES_DATA_ALL:
      return { ...state, sizes: { ...state.sizes, dataAll: action.data } };
    case SITE_SET_SIZES_LAST_PAGE:
      return {
        ...state,
        sizes: {
          ...state.sizes,
          grid: { ...state.sizes.grid, lastPage: action.value },
        },
      };
    case SITE_SET_SIZES_TOTAL_ROWS:
      return {
        ...state,
        sizes: {
          ...state.sizes,
          grid: { ...state.sizes.grid, totalRows: action.value },
        },
      };
    case SITE_SET_SIZES_LOADING:
      return {
        ...state,
        sizes: {
          ...state.sizes,
          grid: { ...state.sizes.grid, loading: action.value },
        },
      };
    case SITE_SET_SIZES_RELOAD_DATA:
      return {
        ...state,
        sizes: {
          ...state.sizes,
          grid: {
            ...state.sizes.grid,
            reloadData: state.sizes.grid.reloadData + 1,
          },
        },
      };
    case SITE_SIZES_TOGGLE_CREATE_EDIT_MODAL:
      return {
        ...state,
        sizes: {
          ...state.sizes,
          createEditModal: !state.sizes.createEditModal,
        },
      };
    case SITE_SIZES_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        sizes: { ...state.sizes, deleteModal: !state.sizes.deleteModal },
      };
    case SITE_SET_SIZES_CURRENT_ROW:
      return {
        ...state,
        sizes: { ...state.sizes, currentRow: action.data },
      };

    case SITE_SET_FORMATS_GRID:
      return {
        ...state,
        formats: {
          ...state.formats,
          grid: action.data,
        },
      };
    case SITE_SET_FORMATS_DATA:
      return {
        ...state,
        formats: {
          ...state.formats,
          grid: { ...state.formats.grid, data: action.data },
        },
      };
    case SITE_SET_FORMATS_DATA_ALL:
      return { ...state, formats: { ...state.formats, dataAll: action.data } };
    case SITE_SET_FORMATS_LAST_PAGE:
      return {
        ...state,
        formats: {
          ...state.formats,
          grid: { ...state.formats.grid, lastPage: action.value },
        },
      };
    case SITE_SET_FORMATS_TOTAL_ROWS:
      return {
        ...state,
        formats: {
          ...state.formats,
          grid: { ...state.formats.grid, totalRows: action.value },
        },
      };
    case SITE_SET_FORMATS_LOADING:
      return {
        ...state,
        formats: {
          ...state.formats,
          grid: { ...state.formats.grid, loading: action.value },
        },
      };
    case SITE_SET_FORMATS_RELOAD_DATA:
      return {
        ...state,
        formats: {
          ...state.formats,
          grid: {
            ...state.formats.grid,
            reloadData: state.formats.grid.reloadData + 1,
          },
        },
      };
    case SITE_FORMATS_TOGGLE_CREATE_EDIT_MODAL:
      return {
        ...state,
        formats: {
          ...state.formats,
          createEditModal: !state.formats.createEditModal,
        },
      };
    case SITE_FORMATS_TOGGLE_DELETE_MODAL:
      return {
        ...state,
        formats: { ...state.formats, deleteModal: !state.formats.deleteModal },
      };

    case SITE_SET_FORMATS_CURRENT_ROW:
      return {
        ...state,
        formats: { ...state.formats, currentRow: action.data },
      };

    default:
      return state;
  }
};
export default SiteReducer;
