/* eslint-disable no-loop-func */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-param-reassign */
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
  fromPromise,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import AuthToken from 'app/helpers/AuthToken';
import { getOAuthRefreshToken } from 'app/helpers/RefreshTokenService';

const httpLink = new HttpLink({ uri: `${process.env.REACT_APP_GRAPH_QL_URL}` });

//  Add cypress identification header
//  It's being used to change the email dispatching service when running cypress tests
const isCypressObject = window.Cypress ? { 'Is-Cypress': true } : {};

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = (error, token = null) => {
  pendingRequests.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  pendingRequests = [];
  isRefreshing = false;
};
/* 
 Method to be used to handle all the GraphQL Errors and
 Log any GraphQL errors or network error that occurred
*/
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (
          err?.extensions?.category?.toLocaleLowerCase() === 'authentication' &&
          err?.message?.toLocaleLowerCase()?.includes('unauthenticated')
        ) {
          let forward$ = null;
          if (!isRefreshing) {
            isRefreshing = true;
            forward$ = fromPromise(
              getOAuthRefreshToken()
                .then((response) => {
                  const { data } = response;
                  const oldHeaders = operation.getContext().headers;
                  // modify the operation context with a new token
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: `Bearer ${data.access_token}`,
                      ...isCypressObject,
                    },
                  });
                  // Store the new tokens for your auth link
                  resolvePendingRequests(null, data.access_token);
                  return data.access_token;
                })
                .catch((error) => {
                  console.warn(error);
                  resolvePendingRequests(error);
                })
            ).filter((value) => Boolean(value));
          } else {
            // Will only emit once the Promise is resolved
            forward$ = fromPromise(
              new Promise((resolve, reject) => {
                pendingRequests.push({ resolve, reject });
              })
                .then((token) => {
                  const oldHeaders = operation.getContext().headers;
                  // modify the operation context with a new token
                  operation.setContext({
                    headers: {
                      ...oldHeaders,
                      authorization: `Bearer ${token}`,
                      ...isCypressObject,
                    },
                  });
                  return forward(operation);
                })
                .catch((error) => Promise.reject(error))
            );
          }
          return forward$.flatMap(() => forward(operation));
        }
        forward(operation);
      }
    }
    if (networkError) console.log(`[Network error]: ${networkError}`);

    return false;
  }
);

const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${AuthToken.getToken()}`,
      ...isCypressObject,
    },
  }));

  return forward(operation);
});

const client = new ApolloClient({
  cache: new InMemoryCache({ addTypename: false }),
  link: ApolloLink.from([authMiddleware, errorLink, httpLink]),
});

export default client;
