import {
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibDiscover,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilArrowTop,
  cilArrowBottom,
  cilAsterisk,
  cilAccountLogout,
  cilAvTimer,
  cilBarcode,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBuilding,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilCheckAlt,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCloudUpload,
  cilCode,
  cilCopy,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilDonate,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEthernet,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFace,
  cilFindReplace,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilGroup,
  cilHappy,
  cilHome,
  cilMeh,
  cilInstitution,
  cilInbox,
  cilInfo,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilCash,
  cilMoon,
  cilMonitor,
  cilNotes,
  cilOptions,
  cilObjectGroup,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPeoplePlus,
  cilPhone,
  cilPrint,
  cilPlus,
  cilPuzzle,
  cilSave,
  cilSad,
  cilSearch,
  cilScrubber,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSignLanguage,
  cilSitemap,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserMinus,
  cilUserFollow,
  cilUserUnfollow,
  cilVideo,
  cilX,
  cilXCircle,
  cilWatch,
  cilWarning,
  cilWallet,
  cilWindowRestore,
  cilZoom,
  cilMinus,
  cilBullhorn,
  cibLinux,
  cibWindows,
  cibGoogleChrome,
  cibMozillaFirefox,
  cibMicrosoftEdge,
  cibInternetExplorer,
  cibSafari,
  cibUbuntu,
  cibArchLinux,
  cibLinuxMint,
  cibDebian,
  cibFedora,
  cibCentos,
  cilSmile,
  cilFilter,
  cilCaretBottom,
  cilCaretTop,
  cilMediaPlay,
  cilMediaPause,
  cisCalendar,
  cisDevices,
  cisDonate,
  cisCartLoaded,
  cilChart,
  cilExternalLink,
  cilImageBroken,
  cilClock,
  cilCenterFocus,
  cilSync,
  cilCamera,
  cilCar,
  cilSortDescending,
  cilSortAscending,
  cilCart,
  cilKey,
  cibMailRu,
  cilPageview,
  cilAddressCard,
  cilCarAlt,
  cilBarChart,
  cisCompare,
  cilNoteAdd,
  cisMail,
  cilIdCard
} from '@coreui/icons-pro';
import logo from '../images/everyangle-logo.png';

export const icons = {
  logo,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowRight,
  cilArrowTop,
  cilArrowBottom,
  cilAsterisk,
  cilAccountLogout,
  cilAvTimer,
  cilBarcode,
  cilBan,
  cilBasket,
  cilBell,
  cilBold,
  cilBuilding,
  cilBookmark,
  cilCalculator,
  cilCalendar,
  cilCloudDownload,
  cilChartPie,
  cilCheck,
  cilCheckAlt,
  cilChevronBottom,
  cilChevronLeft,
  cilChevronRight,
  cilChevronTop,
  cilCircle,
  cilCheckCircle,
  cilCloudUpload,
  cilCode,
  cilCopy,
  cilCommentSquare,
  cilCreditCard,
  cilCursor,
  cilCursorMove,
  cilDrop,
  cilDollar,
  cilDonate,
  cilEthernet,
  cilEnvelopeClosed,
  cilEnvelopeLetter,
  cilEnvelopeOpen,
  cilEuro,
  cilGlobeAlt,
  cilGrid,
  cilFace,
  cilFindReplace,
  cilFile,
  cilFullscreen,
  cilFullscreenExit,
  cilGraph,
  cilGroup,
  cilHome,
  cilHappy,
  cilIdCard,
  cilInstitution,
  cilInbox,
  cilInfo,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInputPower,
  cilItalic,
  cilJustifyCenter,
  cilJustifyLeft,
  cilLaptop,
  cilLayers,
  cilLightbulb,
  cilList,
  cilListNumbered,
  cilListRich,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMeh,
  cilMoon,
  cilMonitor,
  cilCash,
  cilNotes,
  cilOptions,
  cilObjectGroup,
  cilPaperclip,
  cilPaperPlane,
  cilPencil,
  cilPeople,
  cilPeoplePlus,
  cilPhone,
  cilPrint,
  cilPlus,
  cilPuzzle,
  cilSave,
  cilSad,
  cilScrubber,
  cilSettings,
  cilSearch,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShieldAlt,
  cilSignLanguage,
  cilSpeech,
  cilSitemap,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTrash,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserMinus,
  cilUserUnfollow,
  cilVideo,
  cilWatch,
  cilX,
  cilXCircle,
  cilWarning,
  cilWallet,
  cilWindowRestore,
  cilZoom,
  cilMinus,
  cifUs,
  cifBr,
  cifIn,
  cifFr,
  cifEs,
  cifPl,
  cibSkype,
  cibFacebook,
  cibTwitter,
  cibLinkedin,
  cibFlickr,
  cibTumblr,
  cibXing,
  cibGithub,
  cibStackoverflow,
  cibYoutube,
  cibDribbble,
  cibDiscover,
  cibInstagram,
  cibPinterest,
  cibVk,
  cibYahoo,
  cibBehance,
  cibReddit,
  cibVimeo,
  cibCcMastercard,
  cibCcVisa,
  cibStripe,
  cibPaypal,
  cibGooglePay,
  cibCcAmex,
  cilBullhorn,
  cibLinux,
  cibWindows,
  cibGoogleChrome,
  cibMozillaFirefox,
  cibMicrosoftEdge,
  cibInternetExplorer,
  cibSafari,
  cibUbuntu,
  cibArchLinux,
  cibLinuxMint,
  cibDebian,
  cibFedora,
  cibCentos,
  cilSmile,
  cilFilter,
  cilCaretBottom,
  cilCaretTop,
  cilMediaPlay,
  cilMediaPause,
  cisCalendar,
  cisDevices,
  cisDonate,
  cisCartLoaded,
  cilChart,
  cilExternalLink,
  cilImageBroken,
  cilClock,
  cilCenterFocus,
  cilSync,
  cilCamera,
  cilCar,
  cilSortDescending,
  cilSortAscending,
  cilCart,
  cilKey,
  cibMailRu,
  cilPageview,
  cilAddressCard,
  cilCarAlt,
  cilBarChart,
  cisCompare,
  cilNoteAdd,
  cisMail,
};
