import {
  FLOOR_PLAN_SET_DATA,
  FLOOR_PLANS_SET_FILTERS,
  FLOOR_PLANS_SET_FILTERS_DATA,
  FLOOR_PLANS_SET_FILTERS_LOADING,
  FLOOR_PLAN_TOGGLE_CREATE_EDIT_MODAL,
  FLOOR_PLAN_SET_TOTAL_ROWS,
  FLOOR_PLAN_SET_LAST_PAGE,
  FLOOR_PLAN_SET_CURRENT_ROW,
  FLOOR_PLAN_TOGGLE_DELETE_MODAL,
  FLOOR_PLAN_SET_GRID_DATA,
  FLOOR_PLAN_SET_RELOAD_DATA,
  FLOOR_PLAN_SET_RELOAD_FILTERS,
} from './enums/FloorPlanTypes';

export const initialState = {
  filters: {
    loading: false,
    data: {
      sites: [],
      zones: [],
    },
    reloadData: 0,
    selected: [],
  },
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    reloadData: 0,
    loading: false,
  },
  zones_coordinates: [],
  currentRow: {},
  deleteModal: false,
  createEditModal: false,
};

const FloorPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case FLOOR_PLANS_SET_FILTERS_LOADING:
      return { ...state, filters: { ...state.filters, loading: action.value } };
    case FLOOR_PLANS_SET_FILTERS_DATA:
      return { ...state, filters: { ...state.filters, data: action.data } };
    case FLOOR_PLANS_SET_FILTERS:
      return { ...state, filters: { ...state.filters, selected: action.data } };
    case FLOOR_PLAN_TOGGLE_CREATE_EDIT_MODAL:
      return { ...state, createEditModal: !state.createEditModal };
    case FLOOR_PLAN_TOGGLE_DELETE_MODAL:
      return { ...state, deleteModal: !state.deleteModal };
    case FLOOR_PLAN_SET_DATA:
      return state;
    case FLOOR_PLAN_SET_GRID_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case FLOOR_PLAN_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case FLOOR_PLAN_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case FLOOR_PLAN_SET_CURRENT_ROW:
      return { ...state, currentRow: action.data };
    case FLOOR_PLAN_SET_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
        filters: { ...state.filters, reloadData: state.filters.reloadData + 1 },
      };
    case FLOOR_PLAN_SET_RELOAD_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, reloadData: state.filters.reloadData + 1 },
      };
    default:
      return state;
  }
};
export default FloorPlanReducer;
