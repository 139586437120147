import {
  SITEGROUP_SET_GRID,
  SITEGROUP_SET_DATA,
  SITEGROUP_SET_DATA_ALL,
  SITEGROUP_SET_LAST_PAGE,
  SITEGROUP_SET_TOTAL_ROWS,
  SITEGROUP_SET_CURRENT_ROW,
  SITEGROUP_SET_RELOAD_DATA,
  SITEGROUP_SET_LOADING,
  SITEGROUP_SET_LOADING_ALL,
  SITEGROUP_TOGGLE_CREATE_EDIT_MODAL,
  SITEGROUP_TOGGLE_DELETE_MODAL,
} from './enums/SiteGroupsTypes';

const initialState = {
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    reloadData: 0,
    loading: false,
  },
  dataAll: [],
  currentRow: {},
  createEditModal: false,
  deleteModal: false,
  loadingAll: false,
};

const SiteGroupReducer = (state = initialState, action) => {
  switch (action.type) {
    case SITEGROUP_SET_GRID:
      return { ...state, grid: action.data };
    case SITEGROUP_SET_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case SITEGROUP_SET_DATA_ALL:
      return { ...state, dataAll: action.data };
    case SITEGROUP_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case SITEGROUP_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case SITEGROUP_SET_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.value } };
    case SITEGROUP_SET_LOADING_ALL:
      return { ...state, loadingAll: action.value };
    case SITEGROUP_SET_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
      };
    case SITEGROUP_SET_CURRENT_ROW:
      return { ...state, currentRow: action.data };
    case SITEGROUP_TOGGLE_CREATE_EDIT_MODAL:
      return { ...state, createEditModal: !state.createEditModal };
    case SITEGROUP_TOGGLE_DELETE_MODAL:
      return { ...state, deleteModal: !state.deleteModal };
    default:
      return state;
  }
};
export default SiteGroupReducer;
