/* eslint-disable no-case-declarations */
import {
  DEPARTMENT_SET_DATA,
  DEPARTMENT_SET_DATA_ALL,
  DEPARTMENT_SET_CURRENT_ROW,
  DEPARTMENT_SET_TOTAL_ROWS,
  DEPARTMENT_SET_LAST_PAGE,
  DEPARTMENT_SET_LOADING,
  DEPARTMENT_SET_LOADING_ALL,
  DEPARTMENT_SET_RELOAD_DATA,
  DEPARTMENT_SET_CURRENT_FORM_DATA,
  DEPARTMENT_TOGGLE_EDIT_MODAL,
  DEPARTMENT_TOGGLE_DELETE_MODAL,
  DEPARTMENT_TOGGLE_RESEND_MODAL,
} from 'department/enums/DepartmentTypes';

const initialState = {
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    reloadData: 0,
    loading: false,
  },
  dataAll: [],
  currentRow: {},
  currentFormData: {},
  reloadDataInvited: 0,
  loadingAll: true,
  editModal: false,
  deleteModal: false,
  unlockModal: false,
  resendModal: false,
};

const DepartmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEPARTMENT_SET_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case DEPARTMENT_SET_DATA_ALL:
      return { ...state, dataAll: action.data };
    case DEPARTMENT_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case DEPARTMENT_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case DEPARTMENT_SET_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.value } };
    case DEPARTMENT_SET_LOADING_ALL:
      return { ...state, loadingAll: action.value };
    case DEPARTMENT_SET_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
      };
    case DEPARTMENT_TOGGLE_EDIT_MODAL:
      return { ...state, editModal: !state.editModal };
    case DEPARTMENT_TOGGLE_DELETE_MODAL:
      return { ...state, deleteModal: !state.deleteModal };
    case DEPARTMENT_TOGGLE_RESEND_MODAL:
      return { ...state, resendModal: !state.resendModal };
    case DEPARTMENT_SET_CURRENT_ROW:
      return { ...state, currentRow: action.data };
    case DEPARTMENT_SET_CURRENT_FORM_DATA:
      return { ...state, currentFormData: action.data };
    default:
      return state;
  }
};
export default DepartmentReducer;
