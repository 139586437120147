export const MI_SET_GRID = 'MI_SET_GRID';
export const MI_SET_DATA = 'MI_SET_DATA';
export const MI_SET_LAST_PAGE = 'MI_SET_LAST_PAGE';
export const MI_SET_TOTAL_ROWS = 'MI_SET_TOTAL_ROWS';
export const MI_SET_CURRENT_ROW = 'MI_SET_CURRENT_ROW';
export const MI_SET_CURRENT_ROW_VIDEO = 'MI_SET_CURRENT_ROW_VIDEO';
export const MI_SET_RELOAD_DATA = 'MI_SET_RELOAD_DATA';
export const MI_SET_LOADING = 'MI_SET_LOADING';
export const MI_SET_LOADING_MODAL = 'MI_SET_LOADING_MODAL';
export const MI_SET_TOGGLE_MODAL = 'MI_SET_TOGGLE_MODAL';
export const MI_SET_TOGGLE_MODAL_VIDEO = 'MI_SET_TOGGLE_MODAL_VIDEO';
export const MI_SET_ORGANISATIONS = 'MI_SET_ORGANISATIONS';
export const MI_SET_SITES = 'MI_SET_SITES';
export const MI_SET_CLERKS = 'MI_SET_CLERKS';
export const MI_SET_REVIEWERS = 'MI_SET_REVIEWERS';
