export const SWH_SET_SIDEBAR = 'SWH_SET_SIDEBAR';
export const SWH_SET_VIEW = 'SWH_SET_VIEW';
export const SWH_SET_FILTERS = 'SWH_SET_FILTERS';
export const SWH_SET_FILTERS_DATA = 'SWH_SET_FILTERS_DATA';
export const SWH_SET_FILTERS_LOADING = 'SWH_SET_FILTERS_LOADING';
export const SWH_SET_GRID = 'SWH_SET_GRID';
export const SWH_SET_DATA = 'SWH_SET_DATA';
export const SWH_SET_ROW = 'SWH_SET_ROW';
export const SWH_SET_LOADING = 'SWH_SET_LOADING';
export const SWH_SET_GRID_LOADING = 'SWH_SET_GRID_LOADING';
export const SWH_SET_GRID_RELOAD_DATA = 'SWH_SET_GRID_RELOAD_DATA';
export const SWH_SET_GRID_DATA = 'SWH_SET_GRID_DATA';
export const SWH_SET_TOTAL_ROWS = 'SWH_SET_TOTAL_ROWS';
export const SWH_SET_LAST_PAGE = 'SWH_SET_LAST_PAGE';
export const SWH_SET_MODAL_DETAILS = 'SWH_SET_MODAL_DETAILS';
export const SWH_SET_DATA_GRID_ONLY_ROWS_OF_INTEREST =
  'SWH_SET_DATA_GRID_ONLY_ROWS_OF_INTEREST';
