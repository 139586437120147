/* eslint-disable no-case-declarations */
import {
  USER_SET_DATA,
  USER_SET_DATA_ALL,
  USER_SET_CURRENT_ROW,
  USER_SET_TOTAL_ROWS,
  USER_SET_LAST_PAGE,
  USER_SET_LOADING,
  USER_SET_LOADING_ALL,
  USER_SET_RELOAD_DATA,
  USER_SET_CURRENT_FORM_DATA,
  USER_SET_CURRENT_FORM_LOADING,
  USER_TOGGLE_EDIT_MODAL,
  USER_TOGGLE_DELETE_MODAL,
  USER_TOGGLE_UNLOCK_MODAL,
  USER_CHANGE_PERM_ACTION_VALUE,
  USER_SET_REGISTRATION_STEP,
  USER_TOGGLE_RESEND_MODAL,
  USER_TOGGLE_MFA_DISABLE_MODAL,
} from 'user/enums/UserTypes';
import UserInvitationStep from './enums/UserInvitationStep';

const initialState = {
  grid: {
    data: [],
    lastPage: 0,
    totalRows: 0,
    reloadData: 0,
    loading: false,
  },
  dataAll: [],
  reloadDataInvited: 0,
  loadingAll: true,
  editModal: false,
  deleteModal: false,
  unlockModal: false,
  resendModal: false,
  currentRow: {},
  currentFormData: {},
  currentFormLoading: false,
  mfaDisableModal: false,
  /* invited user */
  registrationStep: UserInvitationStep.LOADING_SCREEN,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_SET_DATA:
      return { ...state, grid: { ...state.grid, data: action.data } };
    case USER_SET_DATA_ALL:
      return { ...state, dataAll: action.data };
    case USER_SET_TOTAL_ROWS:
      return { ...state, grid: { ...state.grid, totalRows: action.data } };
    case USER_SET_LAST_PAGE:
      return { ...state, grid: { ...state.grid, lastPage: action.data } };
    case USER_SET_LOADING:
      return { ...state, grid: { ...state.grid, loading: action.value } };
    case USER_SET_LOADING_ALL:
      return { ...state, loadingAll: action.value };
    case USER_SET_RELOAD_DATA:
      return {
        ...state,
        grid: { ...state.grid, reloadData: state.grid.reloadData + 1 },
      };
    case USER_TOGGLE_EDIT_MODAL:
      return { ...state, editModal: !state.editModal };
    case USER_TOGGLE_DELETE_MODAL:
      return { ...state, deleteModal: !state.deleteModal };
    case USER_TOGGLE_UNLOCK_MODAL:
      return { ...state, unlockModal: !state.unlockModal };
    case USER_TOGGLE_RESEND_MODAL:
      return { ...state, resendModal: !state.resendModal };
    case USER_TOGGLE_MFA_DISABLE_MODAL:
      return { ...state, mfaDisableModal: !state.mfaDisableModal };
    case USER_SET_CURRENT_ROW:
      return { ...state, currentRow: action.data };
    case USER_SET_CURRENT_FORM_DATA:
      return { ...state, currentFormData: action.data };
    case USER_SET_CURRENT_FORM_LOADING:
      return { ...state, currentFormLoading: action.value };
    case USER_SET_REGISTRATION_STEP:
      return { ...state, registrationStep: action.data };
    case USER_CHANGE_PERM_ACTION_VALUE:
      const { groupKey, actionKey } = action;
      const actionGroups = state.currentFormData.action_groups ?? [];
      const newState = {
        ...actionGroups,
        [groupKey]: {
          ...actionGroups[groupKey],
          actions: Object.values({
            ...actionGroups[groupKey].actions,
            [actionKey]: {
              ...actionGroups[groupKey].actions[actionKey],
              is_checked: !actionGroups[groupKey].actions[actionKey].is_checked,
            },
          }),
        },
      };

      return {
        ...state,
        currentFormData: {
          ...state.currentFormData,
          action_groups: Object.values(newState),
        },
      };

    default:
      return state;
  }
};
export default UserReducer;
