import {
  IOC_SET_IOCPC,
  IOC_SET_DEMOGRAPHICS,
  IOC_SET_VIEW,
  IOC_SET_FILTERS,
  IOC_SET_DATA,
  IOC_SET_ROW,
  IOC_SET_GRID,
  IOC_SET_SIDEBAR,
  IOC_SET_LOADING,
  IOC_SET_MODAL_DETAILS,
  IOC_SET_FILTERS_DATA,
  IOC_SET_FILTERS_LOADING,
  IOC_SET_FILTERS_SUMMARY,
  IOC_SET_LINECHART_COMPARISON,
  IOC_SET_LINECHART_COMPARISON_LOADING,
  IOC_SET_BARCHART_DEMOGRAPHICS,
  IOC_SET_BARCHART_DEMOGRAPHICS_LOADING,
  IOC_SET_GROUP_BARCHART,
  IOC_SET_GROUP_BARCHART_LOADING,
} from './enums/DashboardTypes';

export const initialState = {
  iocpc: false,
  demographics: true,
  filters: {
    loading: true,
    data: {
      sites: [],
      site_groups: [],
      divisions: [],
      cameras: [],
    },
    selected: {},
    summary: [
      {
        label: 'Selected Date',
        showValue: '',
        iconCls: 'cil-calendar',
      },
      {
        label: 'Previous Date',
        showValue: '',
        iconCls: 'cil-calendar',
      },
      {
        label: 'Historical Dates',
        showValue: '',
        iconCls: 'cil-calendar',
      },
      {
        label: 'Divisions',
        iconCls: 'cil-sitemap',
        cssClass: 'w-75',
        showValue: 'All Divisions',
      },
    ],
  },
  data: {
    selected_date: {
      current: {
        start: '',
        end: '',
      },
      previous: {
        start: '',
        end: '',
      },
    },
    sites_online: 1,
    sites_deployed: 1,
    sites_online_array: [],
    totals: {
      current: 0,
      previous: 0,
      historical: [],
    },
    total_unique_customers: {
      current: 0,
      previous: 0,
    },
    total_minors: {
      current: 0,
      previous: 0,
    },
    total_staffs: {
      current: 0,
      previous: 0,
    },
    gender_breakdown: {
      female: {
        percentage: 0,
        total: 0,
      },
      male: {
        percentage: 0,
        total: 0,
      },
    },
    sentiment_breakdown: {
      current: {
        positive: 0,
        neutral: 0,
        negative: 0,
      },
      previous: {
        positive: 0,
        neutral: 0,
        negative: 0,
      },
    },
    age_breakdown: {
      _10: {
        female: {
          percentage: 0,
          total: 0,
        },
        male: {
          percentage: 0,
          total: 0,
        },
      },
      _11_17: {
        female: {
          percentage: 0,
          total: 0,
        },
        male: {
          percentage: 0,
          total: 0,
        },
      },
      _18: {
        female: {
          percentage: 0,
          total: 0,
        },
        male: {
          percentage: 0,
          total: 0,
        },
      },
      _18_24: {
        female: {
          percentage: 0,
          total: 0,
        },
        male: {
          percentage: 0,
          total: 0,
        },
      },
      _25_34: {
        female: {
          percentage: 0,
          total: 0,
        },
        male: {
          percentage: 0,
          total: 0,
        },
      },
      _35_44: {
        female: {
          percentage: 0,
          total: 0,
        },
        male: {
          percentage: 0,
          total: 0,
        },
      },
      _45_55: {
        female: {
          percentage: 0,
          total: 0,
        },
        male: {
          percentage: 0,
          total: 0,
        },
      },
      _56: {
        female: {
          percentage: 0,
          total: 0,
        },
        male: {
          percentage: 0,
          total: 0,
        },
      },
      _56_99: {
        female: {
          percentage: 0,
          total: 0,
        },
        male: {
          percentage: 0,
          total: 0,
        },
      },
    },
    groups: [],
    divisions: [],
    site_groups: [],
    sites: [],
    cameras: [],
    line_chart: [],
    historical_dates: [],
    best_performing_site: null,
    worst_performing_site: null,
  },
  line_chart_comparison_loading: false,
  line_chart_comparison: [],
  bar_chart_demographics_loading: false,
  bar_chart_demographics: [],
  group_barchart_loading: false,
  group_barchart: [],
  row: [],
  grid: [],
  sidebar: false,
  modalDetails: false,
  loading: false,
};

const DashBoardIOCReducer = (state = initialState, action) => {
  switch (action.type) {
    case IOC_SET_IOCPC:
      return { ...state, iocpc: !state.iocpc };
    case IOC_SET_DEMOGRAPHICS:
      return { ...state, demographics: !state.demographics };
    case IOC_SET_VIEW:
      return { ...state, filters: { ...state.filters, view: action.data } };
    case IOC_SET_DATA:
      return { ...state, data: action.data };
    case IOC_SET_GRID:
      return { ...state, grid: action.data };
    case IOC_SET_ROW:
      return { ...state, row: action.data };
    case IOC_SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, selected: action.data },
      };
    case IOC_SET_FILTERS_SUMMARY:
      return {
        ...state,
        filters: { ...state.filters, summary: action.data },
      };
    case IOC_SET_FILTERS_DATA:
      return { ...state, filters: { ...state.filters, data: action.data } };
    case IOC_SET_FILTERS_LOADING:
      return { ...state, filters: { ...state.filters, loading: action.value } };
    case IOC_SET_SIDEBAR:
      return { ...state, sidebar: !state.sidebar };
    case IOC_SET_MODAL_DETAILS:
      return { ...state, modalDetails: !state.modalDetails };
    case IOC_SET_LOADING:
      return { ...state, loading: action.value };
    case IOC_SET_LINECHART_COMPARISON:
      return { ...state, line_chart_comparison: action.data };
    case IOC_SET_LINECHART_COMPARISON_LOADING:
      return { ...state, line_chart_comparison_loading: action.value };
    case IOC_SET_BARCHART_DEMOGRAPHICS:
      return { ...state, bar_chart_demographics: action.data };
    case IOC_SET_BARCHART_DEMOGRAPHICS_LOADING:
      return { ...state, bar_chart_demographics_loading: action.value };
    case IOC_SET_GROUP_BARCHART:
      return { ...state, group_barchart: action.data };
    case IOC_SET_GROUP_BARCHART_LOADING:
      return { ...state, group_barchart_loading: action.value };

    default:
      return state;
  }
};
export default DashBoardIOCReducer;
