import {
  USER_ANNOUNCEMENT_SET_DATA,
  USER_ANNOUNCEMENT_SET_DATANEW,
  USER_ANNOUNCEMENT_SET_LAST_PAGE,
  USER_ANNOUNCEMENT_SET_TOTAL_ROWS,
  USER_ANNOUNCEMENT_SET_RELOAD_DATA,
  USER_ANNOUNCEMENT_SET_LOADING,
} from './enums/UserAnnouncementTypes';

const initialState = {
  data: [],
  dataNew: [],
  lastPage: 0,
  totalRows: 0,
  currentToOpen: {},
  reloadData: 0,
  loading: false,
};

const UserAnnouncementReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_ANNOUNCEMENT_SET_DATA:
      return { ...state, data: action.data };
    case USER_ANNOUNCEMENT_SET_DATANEW:
      return { ...state, dataNew: action.data };
    case USER_ANNOUNCEMENT_SET_TOTAL_ROWS:
      return { ...state, totalRows: action.data };
    case USER_ANNOUNCEMENT_SET_LAST_PAGE:
      return { ...state, lastPage: action.data };
    case USER_ANNOUNCEMENT_SET_LOADING:
      return { ...state, loading: action.data };
    case USER_ANNOUNCEMENT_SET_RELOAD_DATA:
      return { ...state, reloadData: state.reloadData + 1 };
    default:
      return state;
  }
};
export default UserAnnouncementReducer;
